
import React from 'react'
import Page from '../components/Page'
import einbautiefen from '../data/einbautiefen';

const tableHeader = [
  <div style={{width: 90}}>Beschreibung</div>,
  <div style={{width: 90}}>Bewilligungspflichtig</div>
 ]


export default class extends React.Component {
  render() {
    const kanton = einbautiefen.filter(k => k.id == this.props.match.params.kantonId)[0];
    const zone = kanton.zonen.filter(z => z.id == this.props.match.params.zoneId)[0];
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title={kanton.name + " | " + zone.name}>
        <div className="niceTable" style={{
          height: 500,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {zone.faelle.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => <td key={j}>{r}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>       
      </Page>
    )
  }
}