
import { IonButton, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import React from 'react'
import Page from '../components/Page'
import einbautiefen from '../data/einbautiefen'
import { documentOutline, globeOutline, mapOutline } from 'ionicons/icons';

export default class extends React.Component {
  render() {
    const kanton = einbautiefen.filter(k => k.id == this.props.match.params.kantonId)[0]
    return (
      <Page title={kanton.name}>
        <h2 style={{marginLeft: 15}}>Zone wählen</h2>
        <IonList>
          {kanton.zonen.map(z => (
            <IonItem key={z.id} routerLink={"/uebersichtstabellen/zone/"+kanton.id+"/"+z.id}>
              <IonLabel>
                <h2>{z.name}</h2>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
        <div style={{padding: 15}}>
          {kanton.merkblatt && <a target="_blank" href={kanton.merkblatt}><IonButton><IonIcon icon={documentOutline} style={{marginRight: 10}}/>Merblatt</IonButton></a>}
          {kanton.homepage && <a target="_blank" href={kanton.homepage}><IonButton><IonIcon icon={globeOutline} style={{marginRight: 10}}/>Homepage</IonButton></a>}
          {kanton.gwskarte && <a target="_blank" href={kanton.gwskarte}><IonButton><IonIcon icon={mapOutline} style={{marginRight: 10}}/>Gewässerschutzkarte</IonButton></a>}
        </div>
      </Page>
    )
  }
}