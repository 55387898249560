
import { IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import { documentOutline, globe, grid, gridOutline } from 'ionicons/icons'
import React from 'react'
import Page from '../components/Page'

export default class extends React.Component {
  render() {
    return (
      <Page title="Bauschadstoffe (Asbest, PCB etc.)">

        <IonList>

          <IonItem href="https://polludoc.ch" target="_blank">
            <IonIcon style={{marginRight: 20}} color="medium" icon={documentOutline}/>
            <IonLabel className="ion-text-wrap" >
              <h2>Polludoc</h2>
              <p>Dokumentation des Stands der Technik der Ermittlung, Entfernung und Entsorgung von Bauschadstoffen</p>
            </IonLabel>
          </IonItem>

          <IonItem href="https://www.dropbox.com/s/ggyhdbqpkhm546u/20_04_16_checkliste_gebaeudeschadstoffe.pdf?raw=1" target="_blank">
            <IonIcon style={{marginRight: 20}} color="medium" icon={documentOutline}/>
            <IonLabel className="ion-text-wrap" >
              <h2>Checkliste</h2>
              <p>Gebäudecheck Kanton Zürich für <b>kleine Umbauten</b> mit &lt; CHF 200'000.- Umbaukosten</p>
            </IonLabel>
          </IonItem>

          <IonItem href="https://www.dropbox.com/s/dav0kj5ae74vlie/entsorgungstabelle.xlsx?raw=1" target="_blank">
            <IonIcon style={{marginRight: 20}} color="medium" icon={gridOutline}/>
            <IonLabel className="ion-text-wrap" >
              <h2>Entsorgungstabelle</h2>
              <p>Rückbaumaterial Kanton Zürich mit generellen <b>Entsorgungswegen</b></p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/bauschadstoffe/dringlichkeitsstufen">
            <IonIcon style={{marginRight: 20}} color="medium" />
            <IonLabel className="ion-text-wrap" >
              <h2>Dringlichkeitsstufen nach FACH</h2>
              <p>Ermittlung für die zu treffenden Massnahmen</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/bauschadstoffe/anzahlraumluftmessungen">
            <IonIcon style={{marginRight: 20}} color="medium" />
            <IonLabel className="ion-text-wrap" >
              <h2>Anzahl Raumluftmessungen</h2>
              <p>Bestimmung gemäss FACH-Publikation Nr. 2955</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/bauschadstoffe/uebersichtasbestmineralien">
            <IonIcon style={{marginRight: 20}} color="medium" />
            <IonLabel className="ion-text-wrap" >
              <h2>Übersicht Asbestmineralien</h2>
              <p>Bezeichnung der wichtigsten Asbest-Mineralien</p>
            </IonLabel>
          </IonItem>

        </IonList>

      </Page>
    )
  }
}