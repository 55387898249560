
var verordnungen = [
	{
		"kuerzel":"AltlV",
		"titel":"Verordnung über die Sanierung von belasteten Standorten (Altlasten-Verordnung)",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/19983151/index.html",
		"pdfLink":"https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/1998/2261_2261_2261/20240701/de/pdf-a/fedlex-data-admin-ch-eli-cc-1998-2261_2261_2261-20240701-de-pdf-a-1.pdf",
		"stand":"01.07.2024"
	},
	{
		"kuerzel":"VVEA",
		"titel":"Verordnung über die Vermeidung und die Entsorgung von Abfällen (Abfallverordnung)",
		"htmlLink":"https://www.fedlex.admin.ch/eli/cc/2015/891/de",
		"pdfLink":"https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/2015/891/20240101/de/pdf-a/fedlex-data-admin-ch-eli-cc-2015-891-20240101-de-pdf-a.pdf",
		"stand": "01.01.2024"
	},
	{
		"kuerzel":"VeVA",
		"titel":"Verordnung über den Verkehr mit Abfällen",
		"htmlLink":"https://www.fedlex.admin.ch/eli/cc/2015/891/de",
		"pdfLink":"https://www.admin.ch/opc/de/classified-compilation/20021080/202001010000/814.610.pdf",
		"stand":"01.01.2020"
	},
	{
		"kuerzel":"UVEK/LVA",
		"titel":"Verordnung des UVEK über Listen zum Verkehr mit Abfällen (LVA)",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/20021081/index.html",
		"pdfLink":"https://www.admin.ch/opc/de/classified-compilation/20021081/201801010000/814.610.1.pdf",
		"stand":"01.01.2018"
	},
	{
		"kuerzel":"VBBo",
		"titel":"Verordnung über Belastungen des Bodens",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/19981783/index.html",
		"pdfLink":"https://www.admin.ch/opc/de/classified-compilation/19981783/201604120000/814.12.pdf",
		"stand":"12.04.2016"
	},
	{
		"kuerzel":"GSchV",
		"titel":"Gewässerschutzverordnung",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/19983281/index.html",
		"pdfLink":"https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/1998/2863_2863_2863/20230201/de/pdf-a/fedlex-data-admin-ch-eli-cc-1998-2863_2863_2863-20230201-de-pdf-a.pdf",
		"stand":"01.02.2023"
	},
	{
		"kuerzel":"TBDV",
		"titel":"Verordnung des EDI über Trinkwasser sowie Wasser in öffentlich zugänglichen Bädern und Duschanlagen",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/20143396/index.html",
		"pdfLink":"https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/2017/153/20240201/de/pdf-a/fedlex-data-admin-ch-eli-cc-2017-153-20240201-de-pdf-a.pdf",
		"stand":"01.02.2024"
	},
	{
		"kuerzel": "FrSV",
		"titel": "Verordnung über den Umgang mit Organismen in der Umwelt (Freisetzungsverordnung)",
		"htmlLink": "https://www.fedlex.admin.ch/eli/cc/2008/614/de",
		"pdfLink": "https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/2008/614/20230101/de/pdf-a/fedlex-data-admin-ch-eli-cc-2008-614-20230101-de-pdf-a.pdf",
		"stand": "01.01.2023"
	}
];

var gesetze = [
	{
		"kuerzel":"USG",
		"titel":"Bundesgesetz über den Umweltschutz (Umweltschutzgesetz)",
		"htmlLink":"https://www.admin.ch/opc/de/classified-compilation/19830267/index.html",
		"pdfLink":"https://www.fedlex.admin.ch/filestore/fedlex.data.admin.ch/eli/cc/1984/1122_1122_1122/20240101/de/pdf-a/fedlex-data-admin-ch-eli-cc-1984-1122_1122_1122-20240101-de-pdf-a-2.pdf",
		"stand": "01.01.2024"
	}
];

var andere = [
	{
		// "kuerzel":"Vollzugshilfe Verwertungseignung von Boden",
		"kuerzel":"VHVB",
		"titel":"Beurteilung von Boden im Hinblick auf seine Verwertung (VHVB)",
		"pdfLink":"https://www.dropbox.com/s/meoov4d324u5egr/BAFU-Vollzugshilfe%20Verwertung%20Boden.pdf?raw=1",
		"stand":"2021"
	},
	{
		"kuerzel": "Modul Bauabfälle VVEA-Vollzugshilfe",
		"titel": "Ermittlung von Schadstoffen und Angaben zur Entsorgung von Bauabfällen",
		"stand": "2021",
		"htmlLink": "https://www.bafu.admin.ch/bafu/de/home/themen/abfall/publikationen-studien/publikationen/modul-bauabfaelle.html",
		"pdfLink": "https://www.dropbox.com/s/op9rse5phbojko2/Ermittlung%20von%20Schadstoffen%20und%20Angaben%20zur%20Entsorgung%20von%20Bauabf%C3%A4llen.pdf?raw=1"
	},	
	{
		"kuerzel": "Modul Bauabfälle VVEA-Vollzugshilfe ",
		"titel": "Verwertung von Aushub- und Ausbruchmaterial",
		"stand": "2021",
		"htmlLink": "https://www.bafu.admin.ch/bafu/de/home/themen/abfall/publikationen-studien/publikationen/modul-bauabfaelle.html",
		"pdfLink": "https://www.dropbox.com/s/rqtn3xr5uhduuun/Verwertung%20von%20Aushub-%20und%20Ausbruchmaterial.pdf?raw=1"
	},
	{
		"kuerzel": "FACH Forum Asbest Schweiz",
		"titel": "Asbest in Innenräumen - Dringlichkeit von Massnahmen",
		"stand": "Juli 2018",
		"pdfLink": "https://www.dropbox.com/s/ysu2y4girdbx1ma/FACH1.pdf?raw=1"
	},	{
		"kuerzel": "FACH Forum Asbest Schweiz ",
		"titel": "Asbestsanierungen: Visuelle Kontrollen und Raumluftmessungen",
		"stand": "Dezember 2022",
		"pdfLink": "https://www.dropbox.com/s/6fthraa6m1x4552/Forum%20Asbest%20Schweiz%20Asbestsanierungen%20Visuelle%20Kontrollen%20und%20Raumluftmessungen.pdf?raw=1"
	},
	{
		"kuerzel":"MAK",
		"titel":"Maximale Arbeitsplatz-Konzentrationswerte der Suva",
		"pdfText": "MAK-/BAT-Werte (Erläuterungen), physikalische Einwirkungen, physische Belastungen",
		"pdfLink":"https://www.dropbox.com/scl/fi/i1poysjrdeia9hnamd27i/Erl-uterungen-zu-den-MAK-und-BAT-Werten.pdf?rlkey=pqfxcqf2ejr5umdpwtqs3bgf7&raw=1",
		"excelText": "",
		"excelLink": "https://www.suva.ch/de-ch/services/grenzwerte?sc_lang=de-CH#59317A47178F431595269A7BB5018B2A=%3Flang%3Dde-CH&gnw-location=%2F",
		"stand":"2024"
	}
];

const bestimmungen = {
  verordnungen,
  gesetze,
  andere
}

export default bestimmungen
