
import { IonButton } from '@ionic/react'
import React from 'react'
import Page from '../components/Page'

export default class About extends React.Component {

  state = {counter: 0}

  componentDidUpdate() {
    if(this.state.counter >= 7) {
      this.setState({counter: 0});
      const text = window.prompt("Passwort eingeben");
      if(text == 'wolke77') {
        localStorage.setItem('unlocked', true);
        alert('Interne Funktionen wurden freigeschaltet')
      } else {
        alert('Passwort falsch');
      }
    }
  }
  
  render() {

    console.log(this.state);

    return (
      <Page title="About" large padding>
        <h3>Über die <a target="_blank" href="https://friedlipartner.ch/">FRIEDLIPARTNER AG</a></h3>
        <p>
					Bei vielen Bauvorhaben greifen Fragen der Bereiche Geotechnik, Geologie, Bodenschutz, Altlasten und Gebäudeschadstoffe ineinander. Das interdisziplinäre Team der FRIEDLIPARTNER AG kann solche Fragen integriert, kompetent und kosteneffizient beantworten. 
				</p>
        <h3>Über Grenzwerte.CH</h3>
        <p>
					Diese App wurde von der FRIEDLIPARTNER AG entwickelt, um verschiedene Aufgaben in den Bereichen Altlasten, Gebäudeschadstoffe und Bodenschutz effizienter zu gestalten. Die App gehört der FRIEDLIPARTNER AG und wird von ihr unterhalten. <br />
					Bei Fragen, Anregungen oder Rückmeldungen können Sie gerne Kontakt mit uns aufnehmen - wir haben ein offenes Ohr für Sie. 
				</p>
        <p>
          Datenschutzerklärung: <a target="_blank" href="https://www.friedlipartner.ch/datenschutz/">www.friedlipartner.ch/datenschutz</a>
        </p>
        <a href="mailto:app@friedlipartner.ch">
          <IonButton>Kontakt aufnehmen</IonButton>
        </a>

        <br />
        <br />
        Version 3.4.1 <br />
        © 2024 by FRIEDLIPARTNER AG
        <div style={{height: 50}} onClick={e => this.setState({counter: this.state.counter + 1})}>

        </div>
      </Page>
    )
  }
}