
import React from 'react'
import Page from '../components/Page'

const tableHeader = [
  '',
  <div style={{width: 90}}><b>m<sup>3</sup> fest → t</b></div>,
  <div style={{width: 90}}><b>m<sup>3</sup> lose → t</b></div>,
  <div style={{width: 150}}><b>m<sup>3</sup> fest → m<sup>3</sup> lose</b></div>
 ]

const tableData = [
  ['Asbestzement','1.7','-','-'],
  ['Ausbauasphalt','2.3','1.55','1.3'],
  ['Aushubmaterial','2.0','1.6','1.25'],
  ['Beton','2.4','1.6','1.5'],
  ['Holzzement','1.6','1.3','1.2'],
  ['Mischabbruch','1.8','1.5','1.2'],
  ['Oberboden','1.7','1.4','1.2'],
  ['Schlacken','-','1.4','-'],
  ['Unterboden','1.8','1.45','1.25'],
  ['Holz trocken','0.5','-','-']
];


export default class extends React.Component {
  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Dichten">

        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => <td key={j}>{r}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div> 

        
        {/* <div className="niceTable" style={{
          height: document.getElementsByTagName('ion-content')[0].offsetHeight,
          width: document.getElementsByTagName('ion-content')[0].offsetWidth
        }}>
        <table>
          <thead>
            <tr><th>header 1</th><th>header 2</th><th>header 3</th><th>header 4</th><th>header 5</th></tr>
          </thead>
          <tbody>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
            <tr><td>first</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td><td>fdfdasf</td></tr>
          </tbody>
        </table>
        </div> */}
        

      </Page>
    )
  }
}