
import * as Ion from '@ionic/react';
import React from 'react';

import { IonButtons, IonActionSheet, IonBackdrop, IonToolbar, IonTitle } from '@ionic/react';

/*
	Usage:
	<Page largeTitle padding menu>
		<p>content here</p>
  </Page>
  
  Props: 
    - menu
    - renderButtonsRight
    - renderDirectChildren
*/

const Page = (props) => {

  return (

    <Ion.IonPage>

    <Ion.IonHeader translucent="false">

      <Ion.IonToolbar color={props.headerColor} >
          <Ion.IonButtons slot="start">
            {props.menu && <Ion.IonMenuButton />}
            <Ion.IonBackButton text="Zurück" />
          </Ion.IonButtons>
          <Ion.IonTitle color={props.titleColor ? props.titleColor : "primary"}>{props.title}</Ion.IonTitle>
          <IonButtons slot="end">
            {props.renderButtonsRight && props.renderButtonsRight()}
          </IonButtons>
        </Ion.IonToolbar>
        {props.renderSecondToolbar && props.renderSecondToolbar()}
      </Ion.IonHeader>
      <Ion.IonContent color={props.contentColor} fullscreen="true" forceOverscroll={false}>
        {props.large && (
          <Ion.IonHeader collapse="condense" >              
            <Ion.IonToolbar>
              <Ion.IonTitle size="large" color="primary">{props.title}</Ion.IonTitle>
            </Ion.IonToolbar>
          </Ion.IonHeader>
        )}
        <div style={{padding: props.padding && 16}}>
          {props.children}
        </div>

        {props.renderDirectChildren && props.renderDirectChildren()}
      </Ion.IonContent>

    </Ion.IonPage>

  )
}

export default Page
