
import React from 'react'
import Page from '../components/Page'

const tableData = 
[
  ["Piemont, w","7-8, r","7-8, r","7-9, r", "7-8, r","7-8, r","8-9, r","8-9, w","6-7, w","6-7, w","5-7, w","6-7, g","8-9, w","7-9, g","","","","",""],
  ["Toskana, w","8-9, r","7-9, r","8-9, r", "7-8, r","6-7, r","7-9, w","8-9, w","6-7, w","6-8, w","5-7, g, w","5-8, g","7-8, w","7-8, g","","","","",""],
  ["Spanien Rioja, w","7-8, r","7-8, r","8-9, r", "7-9, r","7-9, w","5-7, w","8-9, w","7-8, w","6-8, g","7-9, g","7-8, w","8-9, w","8-9, w","","","","",""],
  ["Australien, w","9-10, r","8-9, r","7-8, w", "7-8, w","7-8, w","8-9, w","8-9, w","6-7, w","7-9, w","8-9, w","4-5, g","9-10, w","","","","","",""],
  ["Neuseeland rot, w","7-8, r","7-9, r","7-9, r", "6-7, r","6-8, r","","","","","","","","","","","","",""],
  ["Südafrika rot, w","8-9, r","7-8, r", "7-9, r", "7-8, w","8-10, w","","","","","","","","","","","","",""],
  ["Médoc/Graves, w","5-7, r","7-8, r", "7-9, r", "8-9, r","6-8, w","8-9, r","7-9, r","7-8, w","4-7, g","6-8, w","7-8, w","8-10, w","7-10, w","6-8, w","5-7, w","7-8, w","9-10, w","7-8, w","5-9, g"],
  ["St-Emilion/Pomerol, w","5-7, r","7-9, r", "7-9, r", "8-9, r","6-7, w","8-9, r","8-10, r","6-8, w","4-7, g","6-8, w","7-8, w","7-10, w","7-10, w","6-9, w","6-7, g","7-8, w","8-9, w","7-9, w","5-8, g"],
  ["Burgund rot, w","6-7, r","6-8, r", "7-9, r", "8-10, w","6-9, w","7-8, r","7-9, w","6-8, w","5-7, w","8-9, w","7-8, w","8-10, w","7-10, w","7-9, w","","","",""],
  ["Rhône Norden, w ","6-7, r","7-8, r", "8-9, r", "7-9, r","7-9, r","7-9, r","8-9, w","7-8, w","7-9, w","7-9, w","7-8, w","8-10, w","7-9, w","6-7, g","","","",""],
  ["Rhône Süden, w","6-8, r","6-8, r", "6-8, r", "7-8, r","7-9, r","7-9, r","8-9, w","6-8, w","7-8, w","7-9, w","6-8, g","8-9, w","7-8, w","5-7, g","","","",""],
  ["Kalifornien, w","7-8, r","6-8, r","7-8, r","6-9, w","6-8, w","7-8, w"]
];

export default class extends React.Component {
  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Weinjahrgänge" large>
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {['','21','20','19','18','17','16','15','14','13','12','11','10','09','08','07','06','05','04','03'].map(e => <th key={e} style={{backgroundColor: ''}}><div style={{width: 30}}>{e.split(',')[0]}</div></th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    let color = undefined;
                    if(r.split(',')[1] == ' r') color = 'rgb(248, 196, 196)';
                    else if(r.split(',')[1] == ' g') color = 'rgb(192, 231, 165)';
                    else if(j>=1) color="white";
                    
                    if(row.length == 1) return <td style={{paddingTop: 10, width: '100 !important'}} key={j}><b>{r}</b></td>
                    return <td key={j} style={{backgroundColor: color, textAlign: 'center'}}>{r.split(',')[0]}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{marginTop: 20, marginLeft: 15}}>
            <span style={{height: 20, paddingLeft: 20, marginRight: 10, border: '1px solid black', backgroundColor: 'rgb(248, 196, 196)'}}></span>Muss noch länger lagern
          </div>
          <div style={{marginTop: 10, marginLeft: 15}}>
            <span style={{height: 20, paddingLeft: 20, marginRight: 10, border: '1px solid black'}}></span>Kann jetzt getrunken werden, sehr gute Weine lagern
          </div>
          <div style={{marginTop: 10, marginLeft: 15}}>
            <span style={{height: 20, paddingLeft: 20, marginRight: 10, border: '1px solid black', backgroundColor: 'rgb(192, 231, 165)'}}></span>Jetzt trinken
          </div>
        </div> 
      </Page>
    )
  }
}