
import React from 'react'
import Page from './Page'
import neophyten from '../data/neophyten'
import { IonSlide, IonSlides } from '@ionic/react'


function getParams(){
    let split = window.location.href.split('/');
    let lastpiece = split[split.length-1];
    let lastLocation = lastpiece.split('?')[0];
    let params = Object.fromEntries(new URLSearchParams(window.location.search));
    return {lastLocation, params}
}

function replaceUmlaute(arg){
    return arg.replaceAll('ä','ae').replaceAll('ö','oe').replaceAll('ü','ue')
}

export default class Gallery extends React.Component {
render(){
    let id = getParams().lastLocation;
    let params = getParams().params; 
    // See http://idangero.us/swiper/api/ for valid options.
    const slideOpts = {
        initialSlide: params.index || 0,
        speed: 400
    }; 
    let neophyt = neophyten[id]
    let slides = [...Array(neophyt.amountImages)].map((el,i) => (
        <IonSlide key={i}>
            <img src={replaceUmlaute("assets/photos/"+neophyt["Neophyt"]+"/"+neophyt["Neophyt"]+"_"+(i+1)+".jpg")}></img>
        </IonSlide>
    ));
    return(
            <Page contentColor="dark" titleColor="light" headerColor="dark" title={neophyt["Neophyt"]} renderDirectChildren={() => (
                <IonSlides slot="fixed" pager={true} options={slideOpts}>
                    {slides}
                </IonSlides>
            )}>
                
            </Page>
        )
    }
}