
import React from 'react'
import Page from '../components/Page'

var grenzwerte = [
	['GRENZWERTE'],
	['Grenzwert U','Grenzwert U', 'Anhang 3 Ziffer 1'],
	['Richtwert T','Grenzwert T', 'Anhang 3 Ziffer 2'],
	['Inertstoffgrenzwert','Grenzwert B', 'Anhang 5 Ziffer 2.3'],
	['Reststoffgrenzwert', 'Grenzwert C', 'Anhang 5 Ziffer 3.2 - 3.5'],
	['Schlackegrenzwert', 'Grenzwert D', 'Anhang 5 Ziffer 4.4'],
	['Reaktorstoffgrenzwert','Grenzwert E', 'Anhang 5 Ziffer 5.2']
];

var abfallkategorien = [
	['ABFALLKATEGORIEN'],
	['Unverschmutztes Aushubmaterial \n(U-Material)','Unverschmutztes Aushubmaterial \n(A-Material)', 'Anhang 1'],
	['Tolerierbares Aushubmaterial \n(T-Material)','Schwach verschmutztes Aushubmaterial \n(T-Material)', 'Anhang 1'],
	['Inertstoff (IS)','B-Material', '--'],
	['Reststoff (RS)','C-Material', '--'],
	['Schlackestoff','D-Material', '--'],
	['Reaktorstoff (RK)	','E-Material', '--'],
	['Über Reaktorstoff \n(> Reaktorstoff)','Über E-Material \n(> E-Material)', '--']
];

var deponietypen = [
	['DEPONIETYPEN'],
	['"Unternehmerdeponie" / "Aushubdeponie"','A-Deponie/-Kompartiment', 'Art. 35 Absatz 1'],
	['Inertstoffdeponie','B-Deponie/-Kompartiment', 'Art. 35 Absatz 1'],
	['Reststoffdeponie','C-Deponie/-Kompartiment', 'Art. 35 Absatz 1'],
	['Schlackekompartiment','D-Deponie/-Kompartiment', 'Art. 35 Absatz 1'],
	['Reaktordeponie','E-Deponie/-Kompartiment', 'Art. 35 Absatz 1']
];

const tableData = grenzwerte
  .concat(abfallkategorien)
  .concat(deponietypen)

const tableHeader = [
	<b style={{width: 250}}>ALTE BEZEICHNUNG <br />(TVA)</b>,
	<b style={{width: 250}}>NEUE BEZEICHNUNG <br />(VVEA / FP)</b>,
	<b style={{width: 250}}>QUELLE VVEA</b>
]


export default class extends React.Component {
  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Bezeichnungen VVEA vs TVA">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1) return <td style={{paddingTop: 10}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{padding: 15}}>
						Am 01.01.2016 ist die "Technische Verordnung über Abfälle" (TVA)  durch die "Verordnung über die Vermeidung und die Entsorgung von Abfällen" (Abfallverordnung, VVEA) ersetzt worden. In obenstehender Tabelle sind die Bezeichnungen gemäss TVA (bzw. der darauf basierenden Aushubrichtlinie, AHR) denjenigen nach VVEA gegenübergestellt. Die neuen Bezeichnungen der Abfallkategorien stammen aus dem Teil "Verwertung von Aushub- und Ausbruchmaterial" des Moduls "Bauabfälle" der VVEA-Vollzugshilfe.
          </div>
        </div> 
      </Page>
    )
  }

}
