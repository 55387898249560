
import React from 'react'
import Page from '../components/Page'
import { IonBackdrop, IonButton, IonContent, IonItem, IonLabel, IonList, IonPopover, IonThumbnail } from '@ionic/react'
import neophyten from '../data/neophyten'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ArrowContainer, Popover } from 'react-tiny-popover'
import { Capacitor } from '@capacitor/core'

function replaceUmlaute(arg){
    return arg.replaceAll('ä','ae').replaceAll('ö','oe').replaceAll('ü','ue')
}

export default class extends React.Component{

    state = { 
        id: window.location.href.split('/')[window.location.href.split('/').length-1],
        popover1Open: false,
        popover2Open: false,

    }

    constructor(props){
        super(props);
        this.gallery = React.createRef();    
    }
    
    renderGalleryPreview() {
        let neophyt = neophyten[this.state.id]
        let images  = [...Array(neophyt.amountImages)].map((el,i) => (
            <Link to={"/gallery/"+this.state.id+"?index="+i} key={i}>
            <img 
                src={replaceUmlaute("assets/photos/"+neophyt["Neophyt"]+"/"+neophyt["Neophyt"]+"_"+(i+1)+".jpg")}
                style={{'boxShadow':'2px 2px 7px 2px #ddd', width: 60, marginTop: 5, marginRight: 10, borderRadius: 6, aspectRatio: '1/1', objectFit: 'cover'}}
                ></img>
            </Link>
        ));

        return (
            <div style={{marginTop: 5}}>
                {images}
                <span style={{textAlign: 'center'}}>
                    <span onClick={() => {
                        if(Capacitor.getPlatform() == "android") {
                        window.open('https://play.google.com/store/apps/details?id=org.plantnet','_blank')
                        } else {
                        window.open('https://apps.apple.com/us/app/plantnet/id600547573','_blank')
                        }
                    }}>
                        <img style={{width: 60, border: "2px solid #ccc", marginTop: 5, marginRight: 10, borderRadius: 6}} src={"plantnet.png"}></img>
                    </span>
                </span>

            </div>
        )
    }

    renderPopover1(text) {
        return (
                <Popover
                        isOpen={this.state.popover1Open}
                        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                              position={position}
                              childRect={childRect}
                              popoverRect={popoverRect}
                              arrowColor={"#eee"}
                              arrowSize={10}
                              className='popover-arrow-container'
                              arrowClassName='popover-arrow'
                            >
                              <div style={{ backgroundColor: '#eee', padding: 10 }}>
                                <span style={{fontSize: 13}}>
                                <sup><b>1</b></sup> gemäss Liste der invasiven und potenziell invasiven Neophyten der Schweiz (Infoflora, 2021) <br />
                                <sup><b>3</b></sup> PK-Verfahren (Anhang 1.7.2 Bauverfahrensverordnung Kt. ZH) = Private Kontrolle Altlasten<br />
                                <sup><b>4</b></sup> FrSV = Freisetzungsverordnung, Anhang 2 Ziff. 1 FrSV (gemäss Art. 15 Abs. 2)<br />
                                <sup><b>5</b></sup> VHVB = BAFU (Hrsg.) 2021: Beurteilung von Boden im Hinblick auf seine Verwertung. Verwertungseignung von Boden. Ein Modul der Vollzugshilfe Bodenschutz beim Bauen. Bundesamt für Umwelt, Bern. Umwelt-Vollzug Nr. 2112: 34<br />
                                </span>
                              </div>
                            </ArrowContainer>
                          )}                        
                        onClickOutside={() => {this.setState({popover1Open: false})}}
                        padding={10}
                    >
                        <sup><a href="" style={{display: 'inline'}} onClick={(e) => {e.preventDefault(); this.setState({popover1Open: true})}}>{text}</a></sup>
                </Popover>
        )
    }

    renderPopover2(text) {
        return (
                <Popover
                        isOpen={this.state.popover2Open}
                        positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                        content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                              position={position}
                              childRect={childRect}
                              popoverRect={popoverRect}
                              arrowColor={"#eee"}
                              arrowSize={10}
                              className='popover-arrow-container'
                              arrowClassName='popover-arrow'
                            >
                              <div style={{ backgroundColor: '#eee', padding: 10 }}>
                                <span style={{fontSize: 13}}>
                                <sup><b>2</b></sup> gemäss Infoflora Infoblätter Neophyten (2021) <br />
                                <sup><b>6</b></sup> gemäss Erfahrungswerten aus Projekten der FRIEDLIPARTNER AG<br />
                                <sup><b>*</b></sup> Praxishilfe Neophyten Kt. Zürich (Stand: Nov. 2022)<br />
                                </span>
                              </div>
                            </ArrowContainer>
                          )}                        
                        onClickOutside={() => {this.setState({popover2Open: false})}}
                        padding={10}
                    >
                        <sup><a href="" style={{display: 'inline'}} onClick={(e) => {e.preventDefault(); this.setState({popover2Open: true})}}>{text}</a></sup>
                </Popover>
        )
    }

    render() {
        let neophyt = neophyten[this.state.id]
        return (
            <Page title={neophyt["Neophyt"]} padding>
                <img style={{maxHeight: 500}} src={replaceUmlaute("assets/photos/"+neophyt["Neophyt"]+"/"+neophyt["Neophyt"]+"_1.jpg")}></img>
                {this.renderGalleryPreview()}
                <h1><b>{neophyt["Neophyt"]}</b></h1>
                <i><h5>{neophyt["Wissenschaftlicher Name"]}</h5></i>
                <p><b>Status {this.renderPopover1("1,3,4,5")}</b> <br />{neophyt["Status1,3,4,5"]}</p>
                <p><b>VHVB-Verwertungsklasse</b> <br /><Link to="/uebersichtstabellen/lvacodes">{neophyt["VHVB-Verwertungsklasse"]}</Link></p>
                <p><b>Verbreitungsradius (grosse Exemplare){this.renderPopover2("2,6,*")} [m]</b> <br />{neophyt["Verbreitungsradius (grosse Exemplare)2,6,*  [m]"]}</p>
                <p><b>Durchwurzelungstiefe [m]</b> <br />{neophyt["Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]"]}</p>
                <p><b>Mind. Überdeckung Kiesgrube/Deponie [m]</b> <br />{neophyt["Mind. Überdeckung Kiesgrube/Deponie [m]"]}</p>
                <p><b>Bemerkungen</b> <br />{neophyt["Bemerkungen"]}</p>
            </Page>
        )
    }
}