
import { IonButton, IonButtons, IonIcon, IonSearchbar, IonToolbar } from '@ionic/react';
import { search, searchOutline } from 'ionicons/icons';
import React from 'react'
import Page from '../components/Page'
import RechtlicheBestimmungModal from './RechtlicheBestimmungModal';
import SearchBar from './SearchBar';
  
var schwermetalle = [
  ["Anorganika"],
  ["Schwermetalle"],
  ["Aluminium","","","","10 mg/L","","","","","","","","","","","","","","","","",""],
  ["Antimon","3 mg/kg","15 mg/kg","30 mg/kg","","50 mg/kg","50 mg/kg","30 mg/kg","300 mg/kg","","","","","","","","","","","","50 mg/kg",""],
  ["Arsen","15 mg/kg","15 mg/kg","30 mg/kg","0.1 mg/L","50 mg/kg","50 mg/kg","30 mg/kg","30 mg/kg","","","","","","","","","","","","50 mg/kg",""],
  ["Barium","","","","5 mg/L","","","","","","","","","","","","","","","",""],
  ["Blei","50 mg/kg","250 mg/kg","500 mg/kg","1 mg/L","2000 mg/kg","2000 mg/kg","500 mg/kg","500 mg/kg","50 mg/kg","200 mg/kg","200 mg/kg","300 mg/kg","2000 mg/kg","1000 mg/kg","1000 mg/kg","50 mg/kg","200 mg/kg","","","1000 mg/kg","2000 mg/kg"],
  ["Cadmium","1 mg/kg","5 mg/kg","10 mg/kg","0.1 mg/L","10 mg/kg","10 mg/kg","5 mg/kg","5 mg/kg","0.8 mg/kg","2 mg/kg","2 mg/kg","10 mg/kg","30 mg/kg","20 mg/kg","20 mg/kg","0.8 mg/kg","2 mg/kg","","","20 mg/kg","30 mg/kg"],
  ["Chrom gesamt","50 mg/kg","250 mg/kg","500 mg/kg","","1000 mg/kg","1000 mg/kg","500 mg/kg","500 mg/kg","50 mg/kg","","","","","","","50 mg/kg","200 mg/kg","","","",""],
  ["Chrom(III)","","","","2 mg/L","","","","","","","","","","","","","","","","",""],
  ["Chrom(VI)","0.05 mg/kg","0.05 mg/kg","0.1 mg/kg","0.1 mg/L","0.5 mg/kg","0.5 mg/kg","","","","","","","","","","","","","","100 mg/kg",""],
  ["Cobalt","","","","0.5 mg/L","","","250 mg/kg","250 mg/kg","","","","","","","","","","","","",""],
  ["Kupfer","40 mg/kg","250 mg/kg","500 mg/kg","0.5 mg/L","5000 mg/kg","5000 mg/kg","500 mg/kg","500 mg/kg","40 mg/kg","-","150 mg/kg","-","1000 mg/kg","1000 mg/kg","-","40 mg/kg","150 mg/kg","","","1000 mg/kg","1000 mg/kg"],
  ["Molybdän","","","","","","","","","5 mg/kg","","","","","","","5 mg/kg","","","","",""],
  ["Nickel","50 mg/kg","250 mg/kg","500 mg/kg","2 mg/L","1000 mg/kg","1000 mg/kg","500 mg/kg","500 mg/kg","50 mg/kg","","","","","","","50 mg/kg","100 mg/kg","","","1000 mg/kg",""],
  ["Quecksilber","0.5 mg/kg","1 mg/kg","2 mg/kg"," 0.01 mg/L\n 5 mg/kg","5 mg/kg","5 mg/kg","1 mg/kg","1 mg/kg","0.5 mg/kg","0.5 mg/kg","0.5 mg/kg","2 mg/kg","20 mg/kg","","","0.5 mg/kg","0.5 mg/kg","","","2 mg/kg",""],
  ["Silber","","","","","","","","","","","","","","","","","","","","500 mg/kg",""],
  ["Thallium","","","","","","","3 mg/kg","3 mg/kg","","","","","","","","","","","","",""],
  ["Zink","150 mg/kg","500 mg/kg","1000 mg/kg","10 mg/L","5000 mg/kg","5000 mg/kg","2000 mg/kg","4000 mg/kg","150 mg/kg","","","","2000 mg/kg","2000 mg/kg","-","150 mg/kg","300 mg/kg","","","2000 mg/kg","2000 mg/kg"],
  ["Zinn","","","","2 mg/L","","","100 mg/kg","100 mg/kg","","","","","","","","","","","","",""],
];

var andereAnorganika =[
  ["Andere"],
  ["Ammoniak / Ammonium","","","0.5 mg N/L","5 mg N/L","","","","","","","","","","","","","","","","",""],
  ["Cyanid (gesamt)","0.5 mg/kg","","","","","","","","","","","","","","","","","","","",""],
  ["Cyanid (frei)","","","0.02 mg/L","0.1 mg/L","","0.3 mg/L","","","","","","","","","","","","","","",""],
  ["Fluor","","","","","","","","","700 mg/kg","","","","","","","","","","","",""],
  ["Fluorid","","","2 mg/L","10 mg/L","","","","","","","","","","","","700 mg/kg","","","","",""],
  ["Sulfid","","","","0.1 mg/L","","","","","","","","","","","","","","","","",""],
  ["Sulfit","","","","1 mg/L","","","","","","","","","","","","","",""],
  ["Nitrit","","","1 mg/L","1 mg/L","","","","","","","","","","","","","","","","",""],
  ["Phosphat","","","","10 mg/L","","","","","","","","","","","","","","","","",""],
  ["pH","","","","6-12","","","","","","","","","","","","","","","","",""],
];

var aliphatischeKW = [
  ["Organika"],
  ["Σ PFAS","0.1 µg/kg*","2.5 µg/kg*","5 µg/kg*","","","5 µg/kg*","","","","","","","","","","","","","","",""],
  ["Aliphatische KW"],
  ["C5-C10","1 mg/kg","5 mg/kg","10 mg/kg","10 mg/kg","10 mg/kg","100 mg/kg","100 mg/kg","","","","","","","","","","","1 mg/kg","5 mg/kg","5 mg/kg",""],
  ["C10-C40","50 mg/kg","250 mg/kg","500 mg/kg","500 mg/kg","500 mg/kg","5000 mg/kg","5000 mg/kg","","","","","","","","","","","50 mg/kg","250 mg/kg","500 mg/kg",""],
];

var halogenierteKW = [
  ["Halogenierte KW"],
  ["cis-1,2-Dichlorethen","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Dichlormethan (Methylenchlorid)","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Tetrachlorethen (Per)","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Tetrachlormethan (Tetrachlor-kohlenstoff)","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["1,1,1-Trichlorethan","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Trichlorethen (TCE)","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Trichlormethan (Chloroform)","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 1 mg/kg","∑ LCKW\n 5 mg/kg","∑ LCKW\n 10 mg/kg","","","","","","","","","","","∑ LCKW\n 0.1 mg/kg","∑ LCKW\n 0.5 mg/kg","∑ LCKW\n 1 mg/kg",""],
  ["Polychlorierte Biphenyle (PCB)","0.1 mg/kg","0.5 mg/kg","1 mg/kg","1 mg/kg","1 mg/kg","10 mg/kg","10 mg/kg","10 mg/kg","","0.2 mg/kg","0.2 mg/kg","0.1 mg/kg","3 mg/kg","1 mg/kg","1 mg/kg","0.02 mg/kg","0.1 mg/kg","","","1mg/kg","3 mg/kg"],
  ["Halogenierte org. Stoffe, als Chlorid","","","","","","","","10000 mg/kg","","","","","","","","","","","",""],
];

var btex = [
  [<b>BTEX</b>,"∑ BTEX\n1 mg/kg","∑ BTEX\n5 mg/kg","∑ BTEX\n10 mg/kg","∑ BTEX\n10 mg/kg","∑ BTEX\n10 mg/kg","∑ BTEX\n100 mg/kg","∑ BTEX\n10 mg/kg","","","","","","","","","","","∑ BTEX\n1 mg/kg","∑ BTEX\n5 mg/kg","∑ BTEX\n500 mg/kg",""],
  ["Benzol","0.1 mg/kg,\n ∑ BTEX","0.5 mg/kg,\n ∑ BTEX","1 mg/kg,\n ∑ BTEX","1 mg/kg,\n ∑ BTEX","1 mg/kg,\n ∑ BTEX","1 mg/kg,\n ∑ BTEX","1 mg/kg,\n ∑ BTEX","","","","","","","","","","","0.1 mg/kg,\n ∑ BTEX","0.5 mg/kg,\n ∑ BTEX","1 mg/kg",""],
  ["Toluol","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","","","","","","","","","","","∑ BTEX","","∑ BTEX",""],
  ["Ethylbenzol","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","","","","","","","","","","","∑ BTEX","","∑ BTEX",""],
  ["Xylole","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","∑ BTEX","","","","","","","","","","","∑ BTEX","","∑ BTEX",""],
];

var pak = [
  [<b>PAK</b>,"∑ PAK\n3 mg/kg","∑ PAK\n12.5 mg/kg","∑ PAK\n25 mg/kg","∑ PAK\n25 mg/kg","∑ PAK\n25 mg/kg","∑ PAK\n250 mg/kg","∑ PAK\n250 mg/kg","","∑ PAK\n1 mg/kg","∑ PAK\n20 mg/kg","","∑ PAK\n10 mg/kg","","∑ PAK\n100 mg/kg","∑ PAK\n100 mg/kg","∑ PAK\n1 mg/kg","∑ PAK\n10 mg/kg","","","∑ PAK\n100 mg/kg","∑ PAK\n100 mg/kg"],
  ["Acenaphthen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Acenaphthylen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Anthracen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Benz[a]anthracen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Benzo[b]fluor-anthen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Benzo[k]fluor-anthen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Benzo[g,h,i]pery-len","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Benzo[a]pyren","0.3 mg/kg,\n∑ PAK","1.5 mg/kg,\n∑ PAK","3 mg/kg,\n∑ PAK","3 mg/kg,\n∑ PAK","3 mg/kg,\n∑ PAK","10 mg/kg,\n∑ PAK","10 mg/kg","","0.2 mg/kg","2 mg/kg","","1 mg/kg","","10 mg/kg","10 mg/kg","0.2 mg/kg","1 mg/kg","","","10 mg/kg","10 mg/kg"],
  ["Chrysen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Dibenz[a,h]anthra-cen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Fluoranthen","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Fluoren","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Indenox[1,2,3-cd]pyren","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Naphthalin","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Phenantren","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
  ["Pyren","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","∑ PAK","","∑ PAK","∑ PAK","","∑ PAK","","∑ PAK","∑ PAK","","","","","∑ PAK","∑ PAK"],
];

var andereOrganika = [
  ["Andere"],
  ["DOC","","","20 mg C/L","20 mg C/L","","","","","","","","","","","","","","","","",""],
  ["TOC400","","10'000 mg/kg","20'000 mg/kg","20'000 mg/kg","20'000 mg/kg","50'000 mg/kg","50'000 mg/kg","","","","","","","","","","","","","",""],
  ["Dibenzo[1,4]dio-xine und Dibenzofurane","","","","3 µg/kg","3 µg/kg","","","","5 ng I-TEQ/kg","20 ng I-TEQ/kg","20 ng I-TEQ/kg","20 ng I-TEQ/kg","1'000 ng I-TEQ/kg","100 ng I-TEQ/kg","100 ng I-TEQ/kg","5 ng I-TEQ/kg","20 ng I-TEQ/kg","","","",""],
  ["Σ DDT-DDD-DDE","","","","","","","","","","","","","","","","0.002 mg/kg","2 mg/kg","","","",""],
  ["Σ Aldrin, Dieldrin, Endrin","","","","","","","","","","","","","","","","0.002 mg/kg","2 mg/kg","","","",""],
  ["Σ HCH","","","","","","","","","","","","","","","","0.001 mg/kg","1 mg/kg","","","",""],
  ["Chlordan","","","","","","","","","","","","","","","","","1 mg/kg","","","",""],
  ["Endosulfan","","","","","","","","","","","","","","","","","1 mg/kg","","","",""],
];

var sonstigeParameter = [
  ["Sonstige Parameter"],
  ["Mineralischer Fremdstoffanteil","1 Gew.%","5 Gew.%","","","","","","","","","","","","","","1 Gew.%","5 Gew.%","","","",""],
];

const tableData = schwermetalle
  .concat(andereAnorganika)
  .concat(aliphatischeKW)
  .concat(halogenierteKW)
  .concat(btex)
  .concat(pak)
  .concat(andereOrganika)
  .concat(sonstigeParameter)


let tableHeader;
tableHeader = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22];
tableHeader = tableHeader.map(e => <div key={e} style={{width: 100}}>{''}</div>)


export default class extends React.Component {

  state = {modalOpen: false, kuerzel: '', searchOpen: false, searchHasBeenOpen: false}

  renderFirstHeaderRow() {
    return (
      <tr>
        <th style={{width: 100}}></th>

        {/* VVEA */}
        <th style={{width: 100, borderLeft: '1px solid #eee'}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}><div className="headerLink" onClick={() => this.setState({kuerzel: 'VVEA', modalOpen: true})}>VVEA</div></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* VBBo */}
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}><div className="headerLink" onClick={() => this.setState({kuerzel: 'VBBo', modalOpen: true})}>VBBo</div></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* VHVB */}
        <th style={{width: 100}}></th>
        <th style={{width: 100}}><div className="headerLink" onClick={() => this.setState({kuerzel: 'VHVB', modalOpen: true})}>VHVB</div></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* AltlV */}
        <th style={{width: 100}}><div className="headerLink" onClick={() => this.setState({kuerzel: 'AltlV', modalOpen: true})}>AltlV</div></th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>
      </tr>
    )
  }

  renderSecondHeaderRow() {
    return (
      <tr>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* VVEA */}
        <th style={{width: 100, borderRight: '1px solid #eee'}}>U</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>T</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>B</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>C</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>D</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>E</th>
        <th style={{width: 100}}>Zementwerk</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* VBBo */}
        <th style={{width: 100, borderRight: '1px solid #eee'}}>Richtwert</th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}>Prüfwert</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>
        <th style={{width: 100}}></th>
        <th style={{width: 100}}>Sanierungswert</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}></th>

        {/* VHVB */}
        <th style={{width: 100, borderRight: '1px solid #eee'}}>Richtwert</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>Prüfwert</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>A-Wert</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>T-Wert</th>

        {/* AltlV */}
        <th style={{width: 100, borderRight: '1px solid #eee'}}>0-5cm</th>
        <th style={{width: 100, borderRight: '1px solid #eee'}}>0-20cm</th>

      </tr>
    )
  }

  renderThirdHeaderRow() {
    return (
      <tr style={{fontSize: 10}}>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div></th>

        {/* VVEA */}
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 3 <br /> Ziffer 1</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 3 <br /> Ziffer 2</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 5 <br /> Ziffer 2.3</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 5 <br /> Ziffer 3</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 5 <br /> Ziffer 4.4</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Anhang 5 <br /> Ziffer 5.2</th>
        <th style={{width: 100}}>Ersatzrohmaterial</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Ersatzbrennstoff</div></th>

        {/* VBBo */}
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>0-20cm</div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Nahrungspflanzenanbau (0-20cm)</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div>Futterpflanzenanbau (0-20cm)</th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Bodenaufnahme (0-5cm)</div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Landwirtschaft und Gartenanbau (0-20cm)</div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Haus- und Familiengärten (0-20cm)</div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Kinderspielplätze (0-5cm)</div></th>

        {/* VHVB */}
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}></div></th>

        {/* AltlV */}
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Urban und Spielplätze</div></th>
        <th style={{borderRight: '1px solid #eee'}}><div style={{width: 100}}>Landwirtschaft</div></th>

      </tr>
    )
  }

  render() {    

    const content = [...document.getElementsByTagName('ion-content')].pop()
    console.log(content.offsetHeight)
    
    return (
      <Page title="Untergrund und Boden" 
        renderSecondToolbar={() => {
          if(this.state.searchOpen) return (
            <IonToolbar>
              <SearchBar />
            </IonToolbar>
          )
        }}
        renderButtonsRight={() => (
            <IonButton onClick={e => this.setState({searchOpen: !this.state.searchOpen, searchHasBeenOpen: true})}><IonIcon icon={search} /></IonButton>
        )}>
        <div className="niceTable" style={{
          height: this.state.searchOpen ? content.offsetHeight - 52 : (content.offsetHeight + (this.state.searchHasBeenOpen ? 52 : 0)),
          width: content.offsetWidth
        }}>
        {/* {this.state.searchOpen && <SearchBar /> } */}
          <table id="table">
            <thead>
              {this.renderFirstHeaderRow()}
              {this.renderSecondHeaderRow()}
              {this.renderThirdHeaderRow()}
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1) return <td style={{paddingTop: 10, width: '100 !important'}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{marginLeft: 5}}>
              <p><sup>*</sup>Ungewichtete Summe aller gemessenen PFAS. Vom BAFU empfohlen, müssen für jeden Einzelfall vom BAFU genehmigt werden</p>
          </div>
        </div> 
        <RechtlicheBestimmungModal
          dismiss={() => this.setState({modalOpen: false})}
          open={this.state.modalOpen}
          kuerzel={this.state.kuerzel}
        />
      </Page>
    )
  }
  
}