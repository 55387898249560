
import React from 'react'
import Page from '../components/Page';

export default class Dringlichkeitsstufen extends React.Component {
  render() {
    return (
      <Page title="Dringlichkeitsstufen nach FACH">
        <div style={{padding: 13, maxWidth: 500}}>
          <img src="/dringlichkeitsstufen/fig2.png" /> <br/><br/>
          <img src="/dringlichkeitsstufen/fig3.png" /> <br/><br/>
          <img src="/dringlichkeitsstufen/fig4.png" /> <br/><br/>
          <img src="/dringlichkeitsstufen/fig5.png" /> <br/><br/>
        </div>
      </Page>
    )
  }
}