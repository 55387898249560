
import React from 'react'
import Page from '../components/Page'
import lvacodes from '../data/lvacodes'

const tableData = lvacodes;
const tableHeader = [
  <div style={{width: 120}}>VVEA-Abfallkategorie</div>,
  <div style={{width: 180}}>LVA-Code</div>,
];

export default class extends React.Component {
  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="LVA-Codes">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1) return <td style={{paddingTop: 10}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{padding: 15}}>
            Die Bezeichnungen in obenstehender Tabelle sind ein Vorschlag der FRIEDLIPARTNER AG.
            Sie basieren in vielen Fällen auf vom BAFU publizierten Dokumenten.
          </div>
        </div> 

      </Page>
    )
  }
}