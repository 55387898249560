
import React from 'react'
import Page from '../components/Page'
import RechtlicheBestimmungModal from './RechtlicheBestimmungModal';

let tableHeader = [1,2,3,4,5];
tableHeader = tableHeader.map(e => <div key={e} style={{width: 100}}>{e}</div>)


var anorganikaOhneUnterkategorie = [
  ["Anorganika"],
  ["Quecksilber","0.005 mL/m3","0.043 mg/m3","--","0.02 mg/m3"],
  ["Kohlendioxid","5000 mL/m3","9471 mg/m3","5000 mL/m3","9000 mg/m3"],
  ["Schwefel-wasserstoff","10 mL/m3","14.7 mg/m3","5 mL/m3","7.1 mg/m3"]
];

var aliphatischeKW = [
  ["Organika"],
  ["Aliphatische KW"],
  ["Benzin (aromatenfrei)","500 mL/m3","1855 mg/m3","500 mL/m3","2000 mg/m3"],
  ["Leichtbenzin (Aromatengehalt 0-10 Vol.%)","500 mL/m3","1854 mg/m3","500 mL/m3","2000 mg/m3"],
  ["Methan","10'000 mL/m3","6903 mg/m3","10'000 mL/m3","6700 mg/m3"]
];

var PFOS = [
  ["PFOS","","","",<span style={{fontWeight: "normal"}}>{"0.01 mg/m3 (e)"}</span>]
]

var halogenierteKW = [
  ["Halogenierte KW"],
  ["Chlorbenzol","10 mL/m3","48.4 mg/m3","10 mL/m3","46 mg/m3"],
  ["1,1-Dichlorethan","100 mL/m3","426 mg/m3","100 mL/m3","400 mg/m3"],
  ["1,2-Dichlorethan","5 mL/m3","21.3 mg/m3","2 mL/m3","8.2 mg/m3"],
  ["1,1-Dichlorethen","2 mL/m3","8.34 mg/m3","2 mL/m3","8 mg/m3"],
  ["1,2-Dichlorethene","200 mL/m3","834 mg/m3","200 mL/m3","790 mg/m3"],
  ["Dichlormethan (Methylenchlorid)","100 mL/m3","366 mg/m3","50 mL/m3","177 mg/m3"],
  ["1,2-Dichlorpropan","75 mL/m3","365 mg/m3","75 mL/m3","350 mg/m3"],
  ["1,1,2,2-Tetrachlorethan","1 mL/m3","7.22 mg/m3","3 mL/m3","20 mg/m3"],
  ["Tetrachlorethen (Per)","50 mL/m3","357 mg/m3","20 mL/m3","138 mg/m3"],
  ["Tetrachlormethan (Tetrachlor-kohlenstoff)","5 mL/m3","33.1 mg/m3","1 mL/m3","6.4 mg/m3"],
  ["1,1,1-Trichlorethan","200 mL/m3","1148 mg/m3","100 mL/m3","555 mg/m3"],
  ["Trichlorethen (TCE)","50 mL/m3","283 mg/m3","20 mL/m3","110 mg/m3"],
  ["Trichlormethan (Chloroform)","10 mL/m3","51.38 mg/m3","0.5 mL/m3","2.5 mg/m3"],
  ["Vinylchlorid","2 mL/m3","5.38 mg/m3","1 mL/m3","2.6 mg/m3"]
]

var btex = [
  ["BTEX"],
  ["Benzol","1 mL/m3","3.36 mg/m3","0.5 mL/m3","1.6 mg/m3"],
  ["Toluol","50 mL/m3","198 mg/m3","50 mL/m3","190 mg/m3"],
  ["Ethylbenzol","100 mL/m3","457 mg/m3","50 mL/m3","220 mg/m3"],
  ["Xylole","100 mL/m3","457 mg/m3","50 mL/m3","220 mg/m3"]
];

var pak = [
  ["PAK"],
  ["Benzo[a]pyren","0.0002 mL/m3","0.002 mg/m3","--","0.002 mg/m3"],
  ["Naphthalin","10 mL/m3","55.2 mg/m3","10 mL/m3","50 mg/m3"]
]


const tableData = anorganikaOhneUnterkategorie
  .concat(aliphatischeKW)
  .concat(PFOS)
  .concat(halogenierteKW)
  .concat(btex)
  .concat(pak)

export default class extends React.Component {

  state = {modalOpen: false, kuerzel: '' }

  renderFirstHeaderRow() {
    return (
      <tr>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th className="headerLink" onClick={() => this.setState({kuerzel: 'AltlV', modalOpen: true})}><div style={{width: 100}}>AltlV</div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th className="headerLink" onClick={() => this.setState({kuerzel: 'MAK', modalOpen: true})}><div style={{width: 120}}>MAK-Werte SUVA</div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 120}}></div></th>
      </tr>
    );
  }

  renderSecondRow() {
    return (
      <tr>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}>10°C<div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
      </tr>
    )
  }

  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Luft | Grenzwerte">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
            {this.renderFirstHeaderRow()}
            {this.renderSecondRow()}
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1 || row[[0]]=="PFOS") return <td style={{paddingTop: 10}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div> 
        <RechtlicheBestimmungModal
          dismiss={() => this.setState({modalOpen: false})}
          open={this.state.modalOpen}
          kuerzel={this.state.kuerzel}
        />
      </Page>
    )
  }
}