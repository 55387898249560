
import { IonButton, IonSearchbar } from '@ionic/react'
import React from 'react'


export default class extends React.Component {

  state = {
    searchText: '',
    k: 0,
  }

  performSearch() {
    let k = this.state.k;
    console.log('doing k = ', k)

    const tablerows = document.getElementsByTagName('tr');
    const resultRows = []
    for (var i = 0; i<tablerows.length; i++ ) {
      const row = tablerows[i];
      row.id="";
      const firstText = row.childNodes[0].innerHTML;
      if(firstText.toLowerCase().includes(this.state.searchText.toLowerCase())) {
        resultRows.push(row)
      }
    }
  
    // now jump to the row
    if(resultRows.length == 0) alert('Nichts gefunden')
    resultRows[k].scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
    resultRows[k].id = "searchResult"
    this.setState({k: this.state.k+1 >= resultRows.length ? 0 : this.state.k+1});
  
  }

  render() {
    console.log(this.state);
    return (
      <IonSearchbar 
        mode="ios"
        onKeyPress={e => {
          if(e.key == 'Enter'){
            this.performSearch(this.state.searchText)
          }
        }}
        value={this.state.searchText} 
        onIonChange={e => {
          this.setState({
          searchText: e.target.value,
          k: 0
        })}}/>
    );
  }
}