
import { IonButton, IonCard, IonCardHeader, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import React from 'react'
import Page from '../components/Page'
import { arrowForwardOutline, document, documentOutline, grid, gridOutline, informationOutline } from 'ionicons/icons';


export default class extends React.Component {
  render() {
    return (
      <Page large title="Grenzwerte.CH">
        <IonList>
          <IonItem routerLink="/uebersichtstabellen">
            <IonIcon color="primary" icon={gridOutline} style={{marginRight: 20}}/>
            <IonLabel>
              <h1>Übersichtstabellen</h1>
            </IonLabel>
          </IonItem>
          <IonItem routerLink="/rechtlichebestimmungen">
            <IonIcon color="primary" icon={documentOutline} style={{marginRight: 20}}/>
            <IonLabel>
              <h1>Rechtliche Bestimmungen</h1>
            </IonLabel>
          </IonItem>
          <IonItem routerLink="/about">
            <IonIcon color="primary" icon={informationOutline} style={{marginRight: 20}}/>
            <IonLabel>
              <h1>About</h1>
            </IonLabel>
          </IonItem>
        </IonList>
        <div style={{padding: 40, marginTop: 50, textAlign: 'center'}}>
          <img onClick={() => {window.open('https://friedlipartner.ch/', '_blank')}} style={{maxWidth: 500}} src="FP_Logo.svg"/>
        </div>
      </Page>
    )
  }
}