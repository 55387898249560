
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/style.css';
import About from './pages/About';
import Uebersichtstabellen from './pages/Uebersichtstabellen';
import Dichten from './pages/Dichten';
import Schadstoffgrenzwerte from './pages/Schadstoffgrenzwerte';
import Boden from './pages/Boden';
import Wasser from './pages/Wasser';
import Luft from './pages/Luft';
import Bauschadstoffe from './pages/Bauschadstoffe';
import Einbautiefen from './pages/Einbautiefen';
import EinbautiefenDetails from './pages/EinbautiefenDetails';
import LVACodes from './pages/LVACodes';
import Bezeichnungen from './pages/Bezeichnungen';
import Richtpreise from './pages/Richtpreise';
import Weine from './pages/Weine';
import RechtlicheBestimmungen from './pages/RechtlicheBestimmungen';
import { setupConfig } from '@ionic/react';
import EinbautiefenZoneDetails from './pages/EinbautiefenZoneDetails';
import Neophyten from './pages/Neophyten';
import Holzabfaelle from './pages/holzabfaelle';
import Dringlichkeitsstufen from './pages/dringlichkeitsstufen';
import AnzahlRaumluftMessungen from './pages/anzahlraumluftmessungen';
import Uebersichtasbestmineralien from './pages/UebersichtAsbestmineralien';
import NeophytenDetails from './pages/NeophytenDetails';
import Gallery from './components/Gallery';
const mode = (window.location.search.split('mode=')[1]||'').split('&')[0];
if(mode) setupConfig({mode});

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/" exact={true}><Redirect to="/home" /></Route>
        <Route path="/home" exact={true}><Home /></Route>
        
        <Route path="/uebersichtstabellen" exact={true}><Uebersichtstabellen/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte" exact={true}><Schadstoffgrenzwerte/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/boden" exact={true}><Boden/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/wasser" exact={true}><Wasser/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/luft" exact={true}><Luft/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/holzabfaelle" exact={true}><Holzabfaelle/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/neophyten" exact={true}><Neophyten/></Route>
        <Route path="/uebersichtstabellen/schadstoffgrenzwerte/neophyten/:id" exact={true}><NeophytenDetails/></Route>
        <Route path="/uebersichtstabellen/bauschadstoffe" exact={true}><Bauschadstoffe/></Route>
        <Route path="/uebersichtstabellen/bauschadstoffe/dringlichkeitsstufen" exact={true}><Dringlichkeitsstufen/></Route>
        <Route path="/uebersichtstabellen/bauschadstoffe/anzahlraumluftmessungen" exact={true}><AnzahlRaumluftMessungen/></Route>
        <Route path="/uebersichtstabellen/bauschadstoffe/uebersichtasbestmineralien" exact={true}><Uebersichtasbestmineralien/></Route>
        <Route path="/uebersichtstabellen/einbautiefen" exact={true}><Einbautiefen/></Route>
        <Route path="/uebersichtstabellen/zone/:kantonId/:zoneId" component={EinbautiefenZoneDetails}/>
        <Route path="/uebersichtstabellen/einbautiefen/:kantonId" component={EinbautiefenDetails}/>
        <Route path="/uebersichtstabellen/lvacodes" exact={true}><LVACodes /></Route>
        <Route path="/uebersichtstabellen/bezeichnungen" exact={true}><Bezeichnungen/></Route>
        <Route path="/uebersichtstabellen/dichten" exact={true}><Dichten /></Route>
        <Route path="/uebersichtstabellen/richtpreise" exact={true}><Richtpreise /></Route>
        <Route path="/uebersichtstabellen/weine" exact={true}><Weine /></Route>

        <Route path="/rechtlichebestimmungen" exact={true}><RechtlicheBestimmungen/></Route>
        <Route path="/gallery/:latinName" component={Gallery} exact />


        <Route path="/about" exact={true}><About /></Route>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
