
import React from 'react'
import Page from '../components/Page'
import { IonImg } from '@ionic/react'

export default class extends React.Component {
    render(){
        return(
            <Page title="Übersicht Asbestmineralien" renderDirectChildren={() => 
            <div className="niceTable">
                <IonImg style={{width: 1000, marginTop: 20}} src="asbest/uebersichtmineralien.png" />
            </div>}>
            </Page>
        )
    }
}