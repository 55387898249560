
import React from 'react'
import Page from '../components/Page';

export default class AnzahlRaumluftMessungen extends React.Component {
  render() {
    return (
      <Page title="Anzahl Raumluftmessungen">
        <div style={{padding: 13, maxWidth: 250}}>
          <img src="/raumluftmessungen/tabB.png" /> <br/>
        </div>
        <div style={{padding: 13, maxWidth: 500}}>
        <img src="/raumluftmessungen/tabC.png" /> <br/><br/>
        <img src="/raumluftmessungen/Abb1.png" /> <br/><br/>
        </div>
      </Page>
    )
  }
}