
import React from 'react'
import Page from '../components/Page'
import RechtlicheBestimmungModal from './RechtlicheBestimmungModal';

let tableHeader = [1,2,3,4,5,6,7,8];
tableHeader = tableHeader.map(e => <div key={e} style={{width: 100}}>{e}</div>)

var schwermetalle = [
  ["Anorganika"],
  ["Schwermetalle"],
  ["Antimon","0.01 mg/L","","","","","","5 µg/L"],
  ["Arsen","0.05 mg/L","","","","0.1 mg/L","0.1 mg/L","10 µg/L"],
  ["Blei","0.05 mg/L","0.01 mg/L","0.001 mg/L","","0.5 mg/L","0.5 mg/L","10 µg/L"],
  ["Cadmium","0.005 mg/L","0.2 µg/L","0.05 µg/L","","0.1 mg/L","0.1 mg/L","3 µg/L"],
  ["Chrom gesamt","","0.005 mg/L","","","2 mg/L","2 mg/L","50 µg/L"],
  ["Chrom(III)","","∑ Cr(III & VI)\n0.002 mg/L","","","","",""],
  ["Chrom(VI)","0.02 mg/L","∑ Cr(III & VI)\n0.002 mg/L","","","0.1 mg/L","","20 µg/L"],
  ["Cobalt","2 mg/L","","","","0.5 mg/L","0.5 mg/L",""],
  ["Kupfer","1.5 mg/L","0.005 mg/L","0.002 mg/L","","0.5 mg/L","1 mg/L","1 mg/L"],
  ["Molybdän","","","","","","1 mg/L",""],
  ["Nickel","0.7 mg/L","0.01 mg/L","0.005 mg/L","","2 mg/L","2 mg/L","20 µg/L"],
  ["Quecksilber","0.001 mg/L","0.03 µg/L","0.01 µg/L","","","","1 µg/L"],
  ["Silber","0.1 mg/L","","","","","","0.1 mg/L"],
  ["Zink","5 mg/L","0.02 mg/L","0.005 mg/L","","2 mg/L","2 mg/L","5 mg/L"],
  ["Zinn","20 mg/L","","","","","",""],
];

var andereAnorganika = [
  ["Andere"],
  ["Ammoniak / Ammonium","*** 0.5 mg/L","* ∑ NH4+ & NH3\n >10°C 0.2 mg N/L\n<10°C 0.4 mg N/L","","oxisch:\n0.1 mg/L\nanoxisch:\n0.5 mg/L","","","oxisch:\n0.1 mg/L\nanoxisch:\n0.5 mg/L"],
  ["Chlorid","","","","40 mg/L","","",""],
  ["Cyanid (frei)","0.05 mg/L","","","","","",""],
  ["Cyanide","","","","","0.1 mg/L","0.5 mg/L","50 µg/L"],
  ["Fluorid","1.5 mg/L","","","","","","1.5 mg/L"],
  ["Sulfat","","","","40 mg/L","","",""],
  ["Nitrat","","25 mg/L","","25 mg/L","","","40 mg/L"],
  ["Nitrit","*** 0.1 mg/L","","","","(zus. Baustelle)\n 0.3 mg N/L","","0.1 mg/L"],
  ["pH","","","","","6.5 - 9.0","6.5 - 9.0",""],
];

var organikaOhneUnterkategorie = [
  ["Organika"],
  ["KW gesamt","","","","","10 mg/L","20 mg/L","20 µg/L"],
];

var aliphatischeKW = [
  [<b>Aliphatische KW</b>,"","","","0.001 mg/L","","",""],
  ["Summe (C5–C10)","2 mg/L","","","","","",""],
  ["Methyl-tert-butylether (MTBE)   ","0.2 mg/L","","","","","","5 µg/L"],
];

var amine = [
  ["Amine"],
  ["Anilin","0.05 mg/L","","","","","",""],
  ["4-Chloranilin","0.1 mg/L","","","","","",""],
];

var halogenierteKW = [
  ["Halogenierte KW"],
  ["Adsorbierbare organische Halogenver-bindungen (AOX)","","","","0.01 mg X/L","(zus. Baustellen)\n 0.08 mg X/L","",""],
  ["flüchtige Halogenkohlen-wasserstoffe (VOX)","","","","0.001 mg X/L","0.1 mg X/L","0.1 mg X/L","10 µg/L"],
  ["Flüchtige chlorierte KW (FOCl)","","","","","0.1 mg Cl/L","0.1 mg Cl/L",""],
  ["1,2-Dibromethan","0.05 µg/L","","","","","",""],
  ["1,1-Dichlorethan","3 mg/L","","","","","",""],
  ["1,2-Dichlorethan","0.003 mg/L","","","","","","3 µg/L"],
  ["1,1-Dichlorethen","0.03 mg/L","","","","","",""],
  ["1,2-Dichlorethene","0.05 mg/L","","","","","",""],
  ["Dichlormethan (Methylenchlorid)","0.02 mg/L","","","","","","20 µg/L"],
  ["1,2-Dichlorpropan","0.005 mg/L","","","","","",""],
  ["1,1,2,2-Tetrachlorethan","0.001 mg/L","","","","","",""],
  ["Tetrachlorethen (Per)","0.04 mg/L","","","","","","10 µg/L"],
  ["Tetrachlormethan (Tetrachlor-kohlenstoff)","0.002 mg/L","","","","","","2 µg/L"],
  ["1,1,1-Trichlorethan","2 mg/L","","","","","",""],
  ["Trichlorethen (TCE)","0.07 mg/L","","","","","","10 µg/L"],
  ["Trichlormethan (Chloroform)","0.04 mg/L","","","","","","50 µg/L"],
  ["Vinylchlorid","0.5 µg/L","","","","","","0.5 µg/L"],
  ["Chlorbenzol","0.7 mg/L","","","","","",""],
  ["1,2-Dichlorbenzol","3 mg/L","","","","","",""],
  ["1,3-Dichlorbenzol","3 mg/L","","","","","",""],
  ["1,4-Dichlorbenzol","0.01 mg/L","","","","","",""],
  ["1,2,4-Trichlorbenzol","0.4 mg/L","","","","","",""],
  ["Polychlorierte Biphenyle (PCB)","0.1 µg/L","","","","","",""],
];

var btex = [
  [<b>BTEX</b>,"","","","","","","3 µg/L"],
  ["Benzol","0.01 mg/L","","","","","","1 µg/L"],
  ["Toluol","7 mg/L","","","","","",""],
  ["Ethylbenzol","3 mg/L","","","","","",""],
  ["Xylole","10 mg/L","","","","","",""],
];

var nitroverbindungen = [
  ["Nitro-verbindungen"],
  ["2,4-Dinitrophenol","0.05 mg/L","","","","","",""],
  ["Dinitrotoluole","0.5 µg/L","","","","","",""],
  ["Nitrobenzol","0.01 mg/L","","","","","",""],
  ["4-Nitrophenol","2 mg/L","","","","","",""],
];

var phenole = [
  ["Phenole"],
  ["2-Chlorphenol","0.2 mg/L","","","","","",""],
  ["2,4-Dichlorphenol","0.1 mg/L","","","","","",""],
  ["2-Methylphenol (o-Kresol)","2 mg/L","","","","","",""],
  ["3-Methylphenol (m-Kresol)","2 mg/L","","","","","",""],
  ["4-Methylphenol (p-Kresol)","0.2 mg/L","","","","","",""],
  ["Pentachlorphenol (PCP)","0.001 mg/L","","","","","",""],
  ["Phenol","10 mg/L","","","","","",""],
  ["Monocyclische Aromatische Kohlenwasser-stoffe","","","","0.001 mg/L","","",""],
];

var pak = [
  [<b>PAK</b>,"","","","0.1 µg/L","","","0.1 µg/L"],
  ["Acenaphthen","2 mg/L","","","","","",""],
  ["Acenaphthylen","","","","","","",""],
  ["Anthracen","10 mg/L","","","","","",""],
  ["Benz[a]anthracen","0.5 µg/L","","","","","",""],
  ["Benzo[b]fluor-anthen","0.5 µg/L","","","","","",""],
  ["Benzo[k]fluor-anthen","0.005 mg/L","","","","","",""],
  ["Benzo[g,h,i]pery-len","","","","","","",""],
  ["Benzo[a]pyren","0.05 µg/L","","","","","","0.01 µg/L"],
  ["Chrysen","0.05 mg/L","","","","","",""],
  ["Dibenz[a,h]anthra-cen","0.05 µg/L","","","","","",""],
  ["Fluoranthen","1 mg/L","","","","","",""],
  ["Fluoren","1 mg/L","","","","","",""],
  ["Indeno[1,2,3-cd]pyren","0.5 µg/L","","","","","",""],
  ["Naphthalin","1 mg/L","","","","","",""],
  ["Pyren","1 mg/L","","","","","",""],
];

var andereOrganika = [
  ["Andere"],
  ["DOC","","* 1-4 mg/L","","2 mg/L","** 10 mg C/L","",""],
  ["Org. Pestizide je Einzelstoff","","0.1 µg/L","","0.1 µg/L","","","0.1 µg/L"],
  ["BSB5","","* 2-4 mg O2/L","","","** 20 mg O2/L","",""],
  ["Perfluoroctan-sulfonat (PFOS)","","","","","","","0.3 µg/L"],
  ["Perfluorhexan-sulfonat (PFHxS)","","","","","","","0.3 µg/L"],
  ["Perfluoroctansäure (PFOA)","","","","","","","0.5 µg/L"],
  ["PFAS","50 ng TEQ/L","","","","","",""]
];

var physikalischeParameter = [
  ["Physikalische Parameter"],
  ["Temperatur","","","",""," ≤ 30 °C","≤ 60°C",""],
  ["Durchsichtigkeit","","","","","≥ 30 cm","",""],
  ["Gesamte ungelöste Stoffe","","","","","20 mg/L","",""]
];

const tableData = schwermetalle
  .concat(andereAnorganika)
  .concat(organikaOhneUnterkategorie)
  .concat(aliphatischeKW)
  .concat(amine)
  .concat(halogenierteKW)
  .concat(btex)
  .concat(nitroverbindungen)
  .concat(phenole)
  .concat(pak)
  .concat(andereOrganika)
  .concat(physikalischeParameter)

export default class extends React.Component {

  state = {modalOpen: false, kuerzel: '' }

  renderFirstHeaderRow(){
    return (
      <tr>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>

        <th className="headerLink" onClick={() => this.setState({kuerzel: 'AltlV', modalOpen: true})}><div style={{width: 100, borderRight: '1px solid white'}}>AltlV</div></th>

        <th><div style={{width: 100}}></div></th>
        <th><div style={{width: 100}}></div></th>
        <th><div className="headerLink" style={{width: 100}} onClick={() => this.setState({kuerzel: 'GSchV', modalOpen: true})}>GSchV</div></th>
        <th><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}>{'‎'}</div></th>

        <th><div className="headerLink" style={{width: 100}} onClick={() => this.setState({kuerzel: 'TBDV', modalOpen: true})}>TBDV</div></th>

      </tr>
    )
  }

  renderSecondHeaderRow() {
    return (
      <tr style={{fontSize: 10}}>
        <th><div style={{width: 100}}></div></th>
        <th><div style={{width: 100}}>K-Wert</div></th>
        <th><div style={{width: 100}}>Oberflächen-gewässer</div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}>GW als TW</div></th>
        <th><div style={{width: 100}}>Industrieabwasser in</div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div>Höchstwert</th>
      </tr>
    )
  }

  renderThirdHeaderRow() {
    return (
      <tr style={{fontSize: 10}}>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}>gesamt<div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}>gelöst<div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}>Gewässer<div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}>Kanalisation<div style={{width: 100}}></div></th>
        <th style={{borderRight: '1px solid white'}}><div style={{width: 100}}></div></th>
      </tr>
    )
  }

  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Wasser | Grenzwerte">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              {this.renderFirstHeaderRow()}
              {this.renderSecondHeaderRow()}
              {this.renderThirdHeaderRow()}
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1) return <td style={{paddingTop: 10}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div> 
        <RechtlicheBestimmungModal
          dismiss={() => this.setState({modalOpen: false})}
          open={this.state.modalOpen}
          kuerzel={this.state.kuerzel}
        />

      </Page>
    )
  }
}