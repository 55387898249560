
import React from 'react'
import Page from '../components/Page';

var abgetragenerBoden = [
  ['Abgetragener Boden'],
  ['Kat. vp / Wiederverwendung', 5],
  [<div>Kat. ev<sub>I</sub> / Behandlung</div>, 55],
  [<div>Kat. ev<sub>I</sub>, ev<sub>II</sub>, nv-T, nv-B / B-Deponie</div>, 75],
  [<div>Kat. nv-E / E-Deponie</div>, 120],
  [<div>Kat. nv-Neophyten / B-Deponie</div>, 85]
]

var aMaterial = [
  ['A-Material'],
	['A1-A4 / Verwertung', '20']
];

var tAushub = [
	['T-Material'],
	['T1 / Verwertung','15'],
	['T2 / Bodenwäsche','45'],
	['T3 / Bodenwäsche','60'],
	['T4 / Zementwerk','120']
];

var bAushub = [
	['B-Material'],
	['B1 / Bodenwäsche','65'],
	['B2 / Bodenwäsche','75'],
	['B3 / Bodenwäsche','90'],
	['B4 / Zementwerk','120'],
	['B / B-Deponie','65']
];


var eAushub = [
	['E-Material'],
	['E1 / Bodenwäsche','90'],
	['E2 / Bodenwäsche','100'],
	['E3 / Bodenwäsche','120'],
	['E4 / Zementwerk','120'],
	['E / E-Deponie','150']
];

var beton = [
	['Beton- und Mischabbruch'],
	['U-Beton / Bauschuttaufbereitung','5'],
	['T-Beton / Bauschuttaufbereitung','5'],
	['Mischabbruch / Bauschuttaufbereitung','35'],
];

var ausbauasphalt = [
	['Ausbauasphalt'],
	['< 250 mg PAK/kg / Bauschuttaufbereitung','75'],
	['> 250 und < 1000 mg PAK/kg / Belagswerk','110'],
	['> 1000 mg PAK/kg / E-Deponie','170'],
	['> 250 mg PAK/kg / Thermische Verwertung Ausland','120']
];

const tableData = abgetragenerBoden
  .concat(aMaterial)
  .concat(tAushub)
  .concat(bAushub)
  .concat(eAushub)
  .concat(beton)
  .concat(ausbauasphalt);

const tableHeader = [
  <div style={{width: 190}}><b>Entsorgungskategorie / <br />  Entsorgungsweg</b></div>,
  <div style={{width: 130}}><b>Richtpreis CHF/t <br />(inkl. Transport)</b></div>
]

export default class extends React.Component {
  
  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Richtpreise Entsorgung">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => {
                    if(row.length == 1) return <td style={{paddingTop: 10}} key={j}><b>{r}</b></td>
                    return <td key={j}>{r}</td> 
                  })}
                </tr>
              ))}
            </tbody>
          </table>
          <div style={{padding: 15}}>
            Obige Richtpreise wurden von der FRIEDLIPARTNER AG im Januar 2024 aufgrund von Erfahrungswerten bei Entsorgungen in der Region Zürich ermittelt. Die tatsächlichen Preise können höher oder tiefer liegen. Verbindliche Preise können im Einzelfall nur mittels Offerten von Entsorgern eingeholt werden.
          </div>
        </div> 

      </Page>
    )
  }

}