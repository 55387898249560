
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonModal, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { close, documentOutline, globe, gridOutline, pin } from 'ionicons/icons';
import React from 'react'
import Page from '../components/Page'
import bestimmungen from '../data/bestimmungen';
import RechtlicheBestimmungModal from './RechtlicheBestimmungModal';

const {verordnungen,gesetze,andere} = bestimmungen;

export default class extends React.Component {

  state = {
    kuerzel: '',
    open: false
  }

  renderModal() {
    return (
      <RechtlicheBestimmungModal 
        dismiss={() => this.setState({open: false})} 
        open={this.state.open}
        kuerzel={this.state.kuerzel}
        />
    )
  }

  render() {

    return (
      <Page title="Rechtliche Bestimmungen" large renderDirectChildren={this.renderModal.bind(this)}>
        <IonList>
          
        <IonItemDivider mode="ios">Gesetze</IonItemDivider>
          {gesetze.map((v,i) => (
            <IonItem button onClick={() => {this.setState({kuerzel: v.kuerzel, open: true})}} key={v.kuerzel} lines={i==gesetze.length-1 && "none"} slot="start">
            <IonIcon icon={documentOutline} color="medium" slot="start"/>
              <IonLabel>
                <h2>{v.kuerzel}</h2>
                <p>{v.titel}</p>
                <p><i>{v.stand}</i></p>
              </IonLabel>
            </IonItem>
          ))}

        <IonItemDivider mode="ios">Verordnungen</IonItemDivider>
          {verordnungen.map((v,i) => (
            <IonItem button onClick={() => {this.setState({kuerzel: v.kuerzel, open: true})}} key={v.kuerzel} lines={i==verordnungen.length-1 && "none"} slot="start">
              <IonIcon icon={documentOutline} color="medium" slot="start"/>
              <IonLabel>
                <h2>{v.kuerzel}</h2>
                <p>{v.titel}</p>
                <p><i>{v.stand}</i></p>
              </IonLabel>
            </IonItem>
          ))}
          
          
          <IonItemDivider mode="ios">Andere</IonItemDivider>
          {andere.map((v,i) => (
            <IonItem button onClick={() => {this.setState({kuerzel: v.kuerzel, open: true})}} key={v.kuerzel} lines={i==andere.length-1 && "none"} slot="start">
            <IonIcon icon={documentOutline} color="medium" slot="start"/>
              <IonLabel>
                <h2>{v.kuerzel}</h2>
                <p>{v.titel}</p>
                <p><i>{v.stand}</i></p>
              </IonLabel>
            </IonItem>
          ))}

        </IonList>
      </Page>
    )
  }
}