
import React from 'react'
import Page from '../components/Page'
import { Capacitor } from '@capacitor/core';


const tableHeader1 = [
    <th> </th>,
    <th colSpan={3}> VVEA </th>

 ]
 const tableHeader2 = [
  '',
  <div style={{width: 90}}>Stoffliche Verwertung</div>,
  <div style={{width: 90}}>Altholz-feuerungen</div>
 ]

const tableData = [
  [<b>Anorganika</b>,'',''],
  [<b>Schwermetalle</b>,'',''],
  ['Arsen','2 mg/kg','5 mg/kg'],
  ['Blei','30 mg/kg','500 mg/kg'],
  ['Cadmium','2 mg/kg','5 mg/kg'],
  ['Chrom gesamt','30 mg/kg','100 mg/kg'],
  ['Kupfer','20 mg/kg','100 mg/kg'],
  ['Quecksilber','0.4 mg/kg','1 mg/kg'],
  ['Zink','400 mg/kg','1000 mg/kg'],
  [<b>Andere</b>,'',''],
  ['Chlor','600 mg/kg','5000 mg/kg'],
  ['Fluor','100 mg/kg','200 mg/kg'],
  [<b>Organika</b>,'',''],
  [<b>Halogenierte KW</b>,'',''],
  ['Pentachlorphenol (PCP)','3 mg/kg','5 mg/kg'],
  ['Polychlorierte Biphenyle (PCB)','3 mg/kg','5 mg/kg'],
  ['PAK','25 mg/kg','50 mg/kg']
];

export default class extends React.Component {

  render() {
    const content = [...document.getElementsByTagName('ion-content')].pop()
    return (
      <Page title="Holzabfälle | Grenzwerte">
        <div className="niceTable" style={{
          height: content.offsetHeight,
          width: content.offsetWidth
        }}>
          <table>
            <thead>
              <tr>
                {tableHeader1.map((e,i) => e)}
              </tr>
              <tr>
                {tableHeader2.map((e,i) => <th key={i}>{e}</th>)}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row,i) => (
                <tr key={i}>
                  {row.map((r,j) => <td key={j}>{r}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div> 
      </Page>
    )
  }
}