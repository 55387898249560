
var bodenaushub = [
	['ABGETRAGENER BODEN'],
	['Verwertungspflichtig (vp)',	'17 05 04'],							// if more than two use it for subscript 
	[<div>Eingeschränkt verwertbar (ev<sub>I</sub>)</div>,'17 05 93'],	
	[<div>Nur am Entnahmeort verwertbar (ev<sub>II</sub>)</div>,'17 05 93'],
	[<div>Nicht verwertbar (nv), <br /> T-Material</div>,'17 05 93'],
	[<div>Nicht verwertbar (nv), <br /> B-Material</div>,'17 05 96 ak'],
	[<div>Nicht verwertbar (nv), <br /> E-Material</div>,'17 05 90 akb'],
	['Nicht verwertbar (nv), über E-Material','17 05 03 S']
];

var aushubmaterial = [
	['AUSHUBMATERIAL'],
	['A-Material (unverschmutztes Aushubmaterial)',				'17 05 06'],
	['T-Material (schwach verschmutztes Aushubmaterial)',		'17 05 94'],
	['B-Material (wenig verschmutztes Aushubmaterial)',			'17 05 97 ak'],
	['E-Material (stark verschmutztes Aushubmaterial)', 		'17 05 91 akb'],
	['über E-Material (sehr stark verschmutztes Aushubmaterial) ','17 05 05 S'],
	['Knöterich-/Essigbaumhaltig',								'17 05 94']
];

var gleisaushub = [
	['GLEISAUSHUB'],
	['A-Material',									 			'17 05 08'],
	['T-Material (schwach verschmutzter Gleisaushub)',			'17 05 95'],
	['B-Material (wenig verschmutzter Gleisaushub)',			'17 05 98 ak'],
	['E-Material (stark verschmutzter Gleisaushub)',			'17 05 92 akb'],
	['über E-Material (Sehr stark verschmutzter Gleisaushub) ',		'17 05 07 S']
];

var ausbauasphalt = [
	['AUSBAUASPHALT'],
	['≤ 250 mg PAK/kg',					'17 03 02'],
	['> 250 mg und ≤ 1000 mg PAK/kg',	'17 03 01 ak'],
	['> 1000 mg PAK/kg',				'17 03 03 S']
];

var betonabbruch = [
	['BETONABBRUCH'],
	['U-Beton (unverschmutzter Betonabbruch)',					'17 01 01'],
	['T-Beton (schwach verschmutzter Betonabbruch)',			'17 01 01'],
	['B-Beton (wenig verschmutzter Betonabbruch)',				'17 09 04 ak'],
	['E-Beton (stark verschmutzter Betonabbruch)', 				'17 09 04 ak'],
	['über E-Beton (sehr stark verschmutzer Betonausbruch) ', 		'17 09 03 S']
];

var strassenaufbruch = [
	['STRASSENAUFBRUCH'],
	['U-Strassenaufbruch',		'17 01 98'],
	['T-Strassenaufbruch',		'17 01 98'],
	['B-Strassenaufbruch',		'17 09 04 ak'],
	['E-Strassenaufbruch', 		'17 09 04 ak'],
	['über E-Strassenaufbruch', '17 09 03 S']
];

var mischabbruch = [
	['MISCHABBRUCH'],
	['U-Mischabbruch',		'17 01 07'],
	['T-Mischabbruch',		'17 01 07'],
	['B-Mischabbruch',		'17 09 04 ak'],
	['E-Mischabbruch', 		'17 09 04 ak'],
	['über E-Mischabbruch ','17 09 03 S']
];

var andere = [
	['ANDERE BAUABFÄLLE'],
	['Ziegelbruch',																'17 01 02'],
	['Gips unverschmutzt',														'17 08 02'],
	['Bauabfälle mit gebundenen Asbestfasern (z.B. unzerstörte Asbestzement-Platten)',						'17 06 98 '],
	['Bauabfälle mit freien oder sich freisetzenden Asbestfasern (z.B. abgeschliffener Plättlikleber)',  	'17 06 05 S'],
	['Flachglas', 																'17 02 02'],
	['Metalle unverschmutzt', 													'17 04 xy'],
	['Altholz (Baustellenholz, Holz aus dem Innenbereich)', 					'17 02 97 ak'],
	['Problematische Holzabfälle (Holz aus dem Aussenbereich, Bahnschwellen)', 	'17 02 98 S'],
	['Kunststoffe', 															'17 02 03'],
	['PCB-haltige Fugendichtungsmassen', 										'17 09 02 S '],
	['Holzzement', 																'17 06 04'],
	['Steinwolle', 																'17 06 04'],
	['Gips mit organischen Anteilen', 											'17 08 02'],
	['Bausperrgut',																'17 09 04 ak']
];

const lvacodes = bodenaushub
	.concat(aushubmaterial)
	.concat(gleisaushub)
	.concat(ausbauasphalt)
	.concat(betonabbruch)
	.concat(strassenaufbruch)
	.concat(mischabbruch)
	.concat(andere)

export default lvacodes
