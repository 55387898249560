
import React from 'react'

import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import { close, documentOutline, globe, gridOutline, openOutline } from 'ionicons/icons'
import bestimmungen from '../data/bestimmungen';

const {verordnungen,gesetze,andere} = bestimmungen;
const alleBestimmugen = verordnungen.concat(gesetze).concat(andere);

export default class extends React.Component {
  render() {
    console.log(this.props);
    const c = alleBestimmugen.filter(b => b.kuerzel == this.props.kuerzel)[0] || {};
    return (
      <IonModal isOpen={this.props.open} swipeToClose onDidDismiss={this.props.dismiss}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>{c.kuerzel}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={this.props.dismiss}><IonIcon icon={close} /></IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>

            <IonCard mode="ios">
              <IonIcon icon={documentOutline} style={{width: '100%', height: 50, marginTop: 50}} />
              <IonCardHeader>
                <IonCardSubtitle>{c.stand}</IonCardSubtitle>
                <IonCardTitle>{c.kuerzel}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent style={{color: 'black'}}>
                <h2 style={{marginBottom: 15}}>{c.titel}</h2>

                {c.excelText && <p>{c.excelText}</p>}
                { c.excelLink &&
                  <a href={c.excelLink} target="_blank">
                    <IonButton style={{marginRight: 10, marginBottom: 20}}>
                      <IonIcon icon={openOutline} style={{marginRight: 10}}/>Suchmaske SUVA-Webseite
                    </IonButton>
                  </a>
                }

                {c.pdfText && <p>{c.pdfText}</p>}
                <a href={c.pdfLink} target="_blank">
                  <IonButton style={{marginRight: 10}}>
                    <IonIcon icon={documentOutline} style={{marginRight: 10}}/>PDF
                  </IonButton>
                </a>

                {c.htmlText && <p style={{marginTop: 20}}>{c.htmlText}</p>}

                {c.htmlLink && <a href={c.htmlLink} target="_blank">
                  <IonButton>
                    <IonIcon icon={globe} style={{marginRight: 10}}/>Webseite
                  </IonButton>
                </a>}

              </IonCardContent>
            </IonCard>

            <IonCard>
        </IonCard>


          </IonContent>
      </IonModal>
    )
  }
}
