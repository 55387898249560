
var zuerich = {
	id: 'zh',
	name: 'Zürich',
	merkblatt: 'http://www.awel.zh.ch/internet/baudirektion/awel/de/wasser/bewilligungen/bau_gw/_jcr_content/contentPar/downloadlist/downloaditems/1395_1432708966131.spooler.download.1432707853232.pdf/Merkblatt_BauinsGW.pdf',
	homepage: 'http://www.awel.zh.ch/internet/baudirektion/awel/de/wasser/bewilligungen/bau_gw.html',
	gwskarte: 'http://maps.zh.ch/?topic=AWELWWgwsZH&offlayers=bezirkslabels&scale=310000&x=692000&y=252000',
	wappen: 'wappen/zh.gif',
	zonen: [
		{ name: 'üB', id: 'zh1', faelle: 
			[
				['keine Einschränkung der Einbautiefe','Nein'],
			],
		},
		{ name: 'Au', id: 'zh2', faelle: 
			[
				["Fall 1: Oberhalb HW","Nein"],
				["Fall 2: Bis MW","Ja"],
				["Fall 3: Unterhalb MW","Ja, Ausnahmebewilligung"],
				["Fall 4: Geringer Flurabstand","Ja, Ausnahmebewilligung"],
				["Fall 5: Fundation eines Hochhauses","Ja, Ausnahmebewilligung"]			
  			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'zh3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};


var thurgau = {
	id: 'tg',
	name: 'Thurgau',
	merkblatt: 'http://www.umwelt.tg.ch/documents/Vollzug_TG_Einbauten_in_GW.pdf',
	homepage: 'http://www.umwelt.tg.ch/xml_21/internet/de/application/d1073/f8612.cfm',
	gwskarte: 'https://map.geo.tg.ch/apps/mf-geoadmin3/?lang=de&topic=gewaesser&bgLayer=basemap_farbig&layers=gewaesserschutzkarte-gewaesserschutzbereiche,gewaesserschutzkarte-grundwasserschutzareal&layers_opacity=0.9,0.9',
	wappen: 'wappen/tg.gif',
	zonen: [
		{ name: 'üB', id: 'tg1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'tg2', faelle: 
			[
				["Oberhalb HW","Nein"],
				["Bis MW","Ja"],
				["Fall 1: Unterhalb MW","Ja, Ausnahmebewilligung"],
				["Fall 2: Geringer Flurabstand","Ja, Ausnahmebewilligung"]
			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'tg3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};

var luzern = {
	id: 'lu',
	name: 'Luzern',
	merkblatt: 'http://www.umwelt-zentralschweiz.ch/DesktopModules/ViewDocument.aspx?DocumentID=MD93FZnE81Y=',
	homepage: 'https://uwe.lu.ch/themen/gewaesser/nutzungen_eingriffe/bauen_im_grundwasser',
	gwskarte: 'http://www.geo.lu.ch/map/gewaesserschutz/',
	wappen: 'wappen/lu.gif',
	zonen: [
		{ name: 'Au, Schutzzonen  S1, S2, S3', id: 'lu1', faelle: 
			[
				["Einbau stark eingeschränkt","Ja"]
			],
		},
	],
};

var zug = {
	id: 'zg',
	name: 'Zug',
	merkblatt: 'https://www.zg.ch/behoerden/baudirektion/amt-fuer-umweltschutz/download-dokumente/merkblaetter/mb-praxishilfe%20bauen%20im%20grundwasser-einlageblatt%20zum%20zudk-mb.pdf',
	homepage: 'https://www.zg.ch/behoerden/baudirektion/amt-fuer-umweltschutz/grundwasser',
	gwskarte: 'http://www.zugmap.ch/?link=gewaesserschutzkarte',
	wappen: 'wappen/zg.gif',
	zonen: [
		{ name: 'üB', id: 'zg1', faelle: 
			[
				["Kategorie 4: keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'zg2', faelle: 
			[
				["Kategorie 1a: Nutzbare Grundwasservorkommen; Unterhalb MW","Ja"],
				["Kategorie 1b: Randgebiete von nutzbaren Grundwasservorkommen; Unterhalb MW","Ja"],
				["Kategorie 2: Quellgruppen Au (in Nähe von privaten Quellen)","Ja, "],
				["Kategorie 3: Randgebiete zum Schutz von Grundwasservorkommen","in der Regel nein"]				
			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'zg3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};


var stgallen = {
	id: 'sg',
	name: 'St. Gallen',
	merkblatt: 'http://www.umwelt.sg.ch/home/Themen/wasser/Vollzug_Gemeinden/grundwasserschutz/_jcr_content/Par/downloadlist/DownloadListPar/download_1.ocFile/AFU173.pdf',
	homepage: 'http://www.umwelt.sg.ch/home/Themen/wasser/Vollzug_Gemeinden/grundwasserschutz.html',
	gwskarte: 'https://www.geoportal.ch/ch/map/30?y=2774814.00&x=1211826.00&scale=500000&rotation=0',
	wappen: 'wappen/sg.gif',
	zonen: [
		{ name: 'üB', id: 'sg1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"],
			],
		},
		{ name: 'Au', id: 'sg2', faelle: 
			[
				["Oberhalb MW","Ja"],
				["Unterhalb MW","Ja, Ausnahmebewilligung"]
			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'sg3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};

var solothurn = {
	id: 'so',
	name: 'Solothurn',
	merkblatt: '',
	homepage: 'https://www.so.ch/verwaltung/bau-und-justizdepartement/amt-fuer-umwelt/wasser/grundwasser/bewilligungen/',
	gwskarte: 'http://geoweb.so.ch/map/gs',
	wappen: 'wappen/so.gif',
	zonen: [
		{ name: 'üB', id: 'so1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'so2', faelle: 
			[
				["Unterhalb HW","Ja"],
				["Unterhalb MW","Ja, Ausnahmebewilligung"]
			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'so3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};

var aargau = {
	id: 'ag',
	name: 'Aargau',
	merkblatt: 'https://www.ag.ch/media/kanton_aargau/bvu/dokumente_2/umwelt__natur___landschaft/bauen___umwelt_1/grundwasser_1/Merkblatt_Bauten_in_Grundwasserschutzzonen.pdf',
	homepage: 'https://www.ag.ch/de/bvu/umwelt_natur_landschaft/bauen_umwelt/grundwasser_1/grundwasser_3.jsp',
	gwskarte: 'https://www.ag.ch/de/dfr/geoportal/online_karten_agis/online_karten.jsp',
	wappen: 'wappen/ag.gif',
	zonen: [
		{ name: 'üB', id: 'ag1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'ag2', faelle: 
			[
				["Oberhalb MW","Nein"],
				["Unterhalb MW","Ja, Ausnahmebewilligung"]				
			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'ag3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			], 
		},
	],
};

var bern = {
	id: 'bern',
	name: 'Bern',
	merkblatt: 'http://www.bve.be.ch/bve/de/index/wasser/wasser/grundwasser/grundwasserabsenkung.assetref/dam/documents/BVE/AWA/de/BA_GA/BA_GA_Merkblatt_Bauten_Grundwasser.pdf',
	homepage: 'http://www.bve.be.ch/bve/de/index/wasser/wasser/grundwasser/grundwasserabsenkung.html',
	gwskarte: 'http://www.map.apps.be.ch/pub/synserver?project=a42pub_gsk25&userprofile=geo&language=de',
	wappen: 'wappen/be.gif',
	zonen: [
		{ name: 'üB', id: 'be1', faelle: 
			[
				["Fall 1: Oberhalb HW","Nein"],
				["Fall 2: Oberhalb MW","Ja"],
				["Fall 3: Unterhalb MW","Ja"]
			],
		},
		{ name: 'Au', id: 'be2', faelle: 
			[
				["Fall 1: Oberhalb HW","Nein"],
				["Fall 2: Oberhalb MW","Ja"],
				["Fall 4: Unterhalb MW","Ja, Ausnahmebewilligung"],
				["Fall 5: Bauten mit Tiefenfundation (z.B Pfähle)","Ja, Ausnahmebewilligung"],
				["Fall 6: Berücksichtigung von Ersatzmassnahmen","Ja, Ausnahmebewilligung"]
			],
		},		
		{ name: 'Schutzzonen S1, S2, S3', id: 'be3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			],
		}
	],
};

var appenzellinnerrhoden = {
	id: 'appenzellinnerrhoden',
	name: 'Appenzell Innerrhoden',
	merkblatt: '',
	homepage: 'http://www.ai.ch/de/verwaltung/fachbereiche/welcome.php?amt_id=745',
	gwskarte: 'https://www.geoportal.ch/ch/map/30?y=2774814.00&x=1211826.00&scale=500000&rotation=0',
	wappen: 'wappen/ai.gif',
	beschreibung: 'Amt für Umwelt kontaktieren',
	zonen: [
	],
};

var appenzellausserrhoden = {
	id: 'appenzellausserrhoden',
	name: 'Appenzell Ausserrhoden',
	merkblatt: '',
	homepage: 'https://www.ar.ch/verwaltung/departement-bau-und-volkswirtschaft/amt-fuer-umwelt/gewaesserschutz/grundwasserschutz/vorpruefungen-grundwasserschutz-bei-bauvorhaben-und-planungen/',
	gwskarte: 'https://www.geoportal.ch/ch/map/30?y=2774814.00&x=1211826.00&scale=500000&rotation=0',
	wappen: 'wappen/ar.gif',
	zonen: [
		{ name: 'üB', id: 'ar1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'ar2', faelle: 
			[
				 ["Alle Einbautiefen","Ja (falls in qualitativer und quantitativer Hinsicht Gefahr fürs Grundwasser)"]
			],
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'ar3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			],
		},
	],
};

var schaffhausen = {
	id: 'sh',
	name: 'Schaffhausen',
	merkblatt: 'http://www.sh.ch/fileadmin/Redaktoren/Dokumente/Tiefbauamt/BGW_Merkblatt.PDF',
	homepage: 'http://www.sh.ch/Formulare-der-Abteilung-Gewaes.1180.0.html',
	gwskarte: 'http://gis.sh.ch/GIS_SH/?link=StartUmweltEnergieSVG',
	wappen: 'wappen/sh.gif',
	zonen: [
		{ name: 'üB', id: 'sh1', faelle: 
			[
				["keine Einschränkung der Einbautiefe","Nein"]
			],
		},
		{ name: 'Au', id: 'sh2', faelle: 
			[
				["Oberhalb MW","Nein"],
				["Unterhalb MW","Ja, Ausnahmebewilligung"]			
  			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'sh3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			],
		},
	],
};


var baselstadt = {
	id: 'bs',
	name: 'Basel-Stadt',
	merkblatt: 'http://www.aue.bs.ch/dms/aue/download/wasser/bauen-im-grundwasser-merkblatt.pdf',
	homepage: 'http://www.aue.bs.ch/wasser/grundwasser/bauen-im-grundwasser.html',
	gwskarte: 'http://www.stadtplan.bs.ch/geoviewer/index.php?instance=default&language=de&theme=164',
	wappen: 'wappen/bs.gif',
	zonen: [
		{ name: 'alle Bereiche', id: 'bs1', faelle: 
			[
				["Unterhalb HW","Ja"]
			],
		}
	],
};

var basellandschaft = {
	id: 'bl',
	name: 'Basel-Landschaft',
	merkblatt: 'https://www.baselland.ch/fileadmin/baselland/files/docs/bud/aue/wasser/grundwasser/form/merkblatt_bauvorhaben_gw.pdf',
	homepage: 'https://www.baselland.ch/Merkblaetter.315300.0.html',
	gwskarte: 'http://geoview.bl.ch/?map_x=2619192.2772752&map_y=1262840.1089912&map_zoom=4&baselayer_opacity=100&baselayer_ref=grundkarte_sw&tree_group_layers_Grundwasser=grundwasser_quellen_privat%2Cgrundwasser_quellen_oeffentlich%2Cgrundwasser_pumpwerke_privat%2Cgrundwasser_messstellen%2Cgewaesserschutzbereiche%2Crechtsgueltige_grundwasserschutzzonen&tree_groups=Grundwasser',
	wappen: 'wappen/bl.gif',
	zonen: [
		{ name: 'üB', id: 'bl1', faelle: 
			[
				["Oberhalb HW","Nein"],
				["Unterhalb HW","Ja"]
			],
		},
		{ name: 'Au', id: 'bl2', faelle: 
			[
				["Fall A: Oberhalb HW","Nein"],
				["Fall B: Bis MW","Ja"],
				["Fall C: Unterhalb MW","Ja, Ausnahmebewilligung"]
  			], 
		},
		{ name: 'Schutzzonen S1, S2, S3', id: 'bl3', faelle: 
			[
				["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
			],
		}
	],
};

var graubuenden = {
	id: 'graubuenden',
	name: 'Graubünden',
	merkblatt: '',
	homepage: 'https://www.gr.ch/DE/institutionen/verwaltung/ekud/anu/projekte/Wasser/Grundwasser/Bauen-in-gef-Gebiet/Seiten/Bauen-im-besonders-gef%C3%A4hrdeten-Bereich.aspx',
	gwskarte: 'http://map.geo.gr.ch/gr_webmaps/wsgi/theme/Gewaesserschutzkarte',
	wappen: 'wappen/gr.gif',
	zonen: [
		{ name: 'Au, Ao, Zu, Zo, S1, S2, S3', id: 'gr1', faelle: 
			[
				["Arbeiten, die eine Gefahr für die Gewässer darstellen können","Ja"]
			],
		},
	],
};

var wallis = {
	id: 'wallis',
	name: 'Wallis',
	merkblatt: '',
	homepage: 'https://www.vs.ch/de/web/spe/eaux-souterraines',
	gwskarte: 'https://sitonline.vs.ch/environnement/eaux_souterraines/de/',
	wappen: 'wappen/vs.gif',
	zonen: [
		{ name: 'Alle Bereiche', id: 'vs1', faelle: 
			[
				["Falls Grundwasserressourcen potentiell gefährdet werden","Ja"]
			],
		},
	],
};

var freiburg = {
	id: 'freiburg',
	name: 'Freiburg',
	merkblatt: '',
	homepage: 'http://www.fr.ch/eau/de/pub/grundwasser/bewilligungen.htm',
	gwskarte: 'http://map.geo.fr.ch/?dataTheme=Environnement&theme=CARTES_COULEUR&lang=de',
	wappen: 'wappen/fr.gif',
	zonen: [
		{ name: 'Au, Ao, Zu, Zo, S1, S2, S3', id: 'fr1', faelle: 
			[
				["Bei Freilegen des Grundwassers und Erstellen von Anlagen, die Deckschichten oder Grundwasserstauer verletzen","Ja"]
			],
		},
	],
};


/* 
	ZENTRALSCHWEIZ:
	Für die Kantone Uri, Schwyz, Nidwalden und Obwalden sind die Zonen gleich. Deshalb wurden sie
	in eine separate Variable gelegt, welche dann einfach referenziert wird.
*/

var zentralschweizZonen = [
	{ name: 'üB', id: 'zs1', faelle: 
		[
			["keine Einschränkung der Einbautiefe","Nein"]
		],
	},
	{ name: 'Au', id: 'zs2', faelle: 
		[
			["Oberhalb HW","Nein.  Nur für Anlagen, die wassergefährdende Stoffe enthalten Bewilligung notwendig."],
			["Bis MW","Ja"],
			["Unterhalb MW","Ja, Ausnahmebewilligung"]			
		], 
	},
	{ name: 'Schutzzonen S1, S2, S3', id: 'zs3', faelle: 
		[
			["Einbau stark eingeschränkt","Ja. Zusätzliche Auflagen"]
		], 
	},
];

var homepageZentralschweiz = 'http://www.umwelt-zentralschweiz.ch/i4Def.aspx?tabindex=0&tabid=398';



var glarus = {
	id: 'gl',
	name: 'Glarus',
	merkblatt: 'http://www.gl.ch/documents/BauenImGrundwassergebiet.pdf',
	homepage: 'http://www.gl.ch/xml_1/internet/de/application/d1256/d35/d348/d1156/f369.cfm',
	gwskarte: 'https://map.geo.gl.ch/Public?visibleLayers=Karte%20grau',
	wappen: 'wappen/gl.gif',
	beschreibung: 'Der Kanton Glarus verweist auf das Merkblatt der Zentralschweizer Umweltschutzdirektionen.',
	zonen: zentralschweizZonen,
};

var uri = {
	id: 'ur',
	name: 'Uri',
	merkblatt: 'http://www.umwelt-zentralschweiz.ch/DesktopModules/ViewDocument.aspx?DocumentID=MD93FZnE81Y=',
	homepage: 'http://www.ur.ch/de/verwaltung/dienstleistungen/welcome.php?dienst_id=4172',
	homepageZentralschweiz: homepageZentralschweiz,
	gwskarte: 'http://www.geo.ur.ch/viewer?Layers=Gew%C3%A4sserschutzbereiche&Visibility=1&Opacity=1&Zoom=11&Lat=46.7917258510636&Lng=8.601951599121093&mapType=Luftbild',
	wappen: 'wappen/ur.gif',
	zonen: zentralschweizZonen
};

var schwyz = {
	id: 'sz',
	name: 'Schwyz',
	merkblatt: 'http://www.umwelt-zentralschweiz.ch/DesktopModules/ViewDocument.aspx?DocumentID=MD93FZnE81Y=',
	homepage: 'http://www.sz.ch/xml_1/internet/de/application/d999/d2523/d2524/d1140/p1199.cfm',
	homepageZentralschweiz: homepageZentralschweiz,
	gwskarte: 'https://map.geo.sz.ch/?map_x=695500&map_y=212500&map_zoom=1&tree_group_layers_grp_Umws_Lae_Gsk=ly_afu_gsk_gs_bereich_au%2Cly_afu_gsk_gs_bereich_ao%2Cly_afu_gsk_gw_schutzareale%2Cly_afu_gsk_gw_schutzzonen%2Cly_afu_gsk_gw_schutzzonen_status%2Cly_afu_gsk_gw_vorkommen_gespannt%2Cly_afu_gsk_gw_isohypsen%2Cly_afu_gsk_gw_vorkommen%2Cly_afu_gsk_bachw_fassung_waermenutzung%2Cly_afu_gsk_bachw_fassung_trink_brauchwasser%2Cly_afu_gsk_gw_fassung_waermenutzung%2Cly_afu_gsk_gw_fassung_trink_brauchwasser%2Cly_afu_gsk_quellen_ungefasst%2Cly_afu_gsk_quellen_gefasst&tree_groups=grp_Umws_Lae_Gsk&baselayer_opacity=100&baselayer_ref=grundkarte',
	wappen: 'wappen/sz.gif',
	zonen: zentralschweizZonen
};

var nidwalden = {
	id: 'nw',
	name: 'Nidwalden',
	merkblatt: 'http://www.umwelt-zentralschweiz.ch/DesktopModules/ViewDocument.aspx?DocumentID=MD93FZnE81Y=',
	homepage: 'http://www.nw.ch/de/onlinemain/dienstleistungen/?dienst_id=1752',
	homepageZentralschweiz: homepageZentralschweiz,
	gwskarte: 'http://map.gis-daten.ch/nw_grundwasserthemen',
	wappen: 'wappen/nw.gif',
	zonen: zentralschweizZonen
};

var obwalden = {
	id: 'ow',
	name: 'Obwalden',
	merkblatt: 'http://www.umwelt-zentralschweiz.ch/DesktopModules/ViewDocument.aspx?DocumentID=MD93FZnE81Y=',
	homepage: 'http://www.ow.ch/de/verwaltung/dienstleistungen/?dienst_id=2898',
	homepageZentralschweiz: homepageZentralschweiz,
	gwskarte: 'http://map.gis-daten.ch/ow_gewaesserschutz',
	wappen: 'wappen/ow.gif',
	zonen: zentralschweizZonen
};

// ENDE ZENTRALSCHWEIZ -----------------------------------------------------

var einbautiefen = [
	aargau,
	appenzellausserrhoden,
	appenzellinnerrhoden,
	basellandschaft,
	baselstadt,
	bern,
	freiburg,
	glarus,
	graubuenden,
	luzern,
	nidwalden,
	obwalden,
	schaffhausen,
	schwyz,
	solothurn,
	stgallen,
	thurgau,
	uri,
	wallis,
	zug,
	zuerich
];

export default einbautiefen
