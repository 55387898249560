
import { IonItem, IonLabel, IonList } from '@ionic/react'
import React from 'react'
import Page from '../components/Page'

export default class extends React.Component {
  render() {
    return (
      <Page title="Schadstoffgrenzwerte" large>

        <IonList>

          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte/boden">
            <IonLabel className="ion-text-wrap" >
              <h2>Untergrund und Boden</h2>
              <p>Nach VVEA, VBBo, VHVB, AltlV</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte/wasser">
            <IonLabel className="ion-text-wrap" >
              <h2>Wasser</h2>
              <p>Nach AltlV, GSchV, TBDV</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte/luft">
            <IonLabel className="ion-text-wrap" >
              <h2>Luft</h2>
              <p>Nach AltlV, MAK</p>
            </IonLabel>
          </IonItem>
          
          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte/holzabfaelle">
            <IonLabel className="ion-text-wrap" >
              <h2>Holzabfälle</h2>
              <p>Nach VVEA</p>
            </IonLabel>
          </IonItem>
        </IonList>
        
      </Page>
    )
  }
}