
import React from 'react'
import Page from '../components/Page'
import { IonAvatar, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react'
import neophyten from '../data/neophyten'
import { image } from 'ionicons/icons'

function replaceUmlaute(arg){
    return arg.replaceAll('ä','ae').replaceAll('ö','oe').replaceAll('ü','ue')
}

export default class extends React.Component{
    render() {
        return (
            <Page title="Neophyten" large>
                <div style={{height: 3}}></div>
                <IonList>
                    {neophyten.map((n,i) => 
                        <IonItem key={i} routerLink={'/uebersichtstabellen/schadstoffgrenzwerte/neophyten/'+i}>
                            <IonAvatar slot="start" style={{width: 100, height: 100}}>
                                <img style={{borderRadius: 5}} src={replaceUmlaute("assets/photos/"+n["Neophyt"]+"/"+n["Neophyt"]+"_1.jpg")}/>
                            </IonAvatar>
                            <IonLabel>
                                <IonLabel  >
                                    <h2>{n["Neophyt"]}</h2>
                                    <p>{n["Wissenschaftlicher Name"]}</p>
                                </IonLabel>
                            </IonLabel>
                        </IonItem>
                    )}
                </IonList>
            </Page>
        )
    }
}