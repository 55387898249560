
import { IonItem, IonLabel, IonList } from '@ionic/react'
import React from 'react'
import Page from '../components/Page'
import einbautiefen from '../data/einbautiefen'

export default class extends React.Component {
  render() {
    return (
      <Page title="Bauen im Grundwasser">
        <IonList>
          {einbautiefen.map((kanton,i) => 
            <IonItem key={i} routerLink={'/uebersichtstabellen/einbautiefen/'+kanton.id}>
              <img src={kanton.wappen} style={{width: 30, marginRight: 10}}/>
              <IonLabel>
                <h2>{kanton.name}</h2>
              </IonLabel>
            </IonItem>
          )}
        </IonList>
      </Page>
    )
  }
}