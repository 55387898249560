
import { IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonText } from '@ionic/react'
import { lockOpen, lockOpenOutline } from 'ionicons/icons'
import React from 'react'
import Page from '../components/Page'
import { Capacitor } from '@capacitor/core'

export default class extends React.Component {

  renderInternalStuff() {
    return (
      <>
        <IonItemDivider mode="ios">Interne Funktionen</IonItemDivider>
        <IonItem routerLink="/uebersichtstabellen/richtpreise">
          <IonIcon icon={lockOpenOutline} color="medium" slot="start"/>
          <IonLabel className="ion-text-wrap" >
            <h2>Richtpreise Entsorgung</h2>
            <p>Erfahrungswerte für Entsorgungspreise für mineralische Bauabfälle </p>
          </IonLabel>
        </IonItem>
        <IonItem button onClick={() => {
            if(Capacitor.getPlatform() == "android") {
              window.open('https://play.google.com/store/apps/details?id=com.bitstem.vinomobile.vintages','_blank')
            } else {
              window.open('https://apps.apple.com/de/app/weinjahrg%C3%A4nge/id327068055','_blank')
            }
          }}>
          <IonIcon icon={lockOpenOutline} color="medium" slot="start"/>
          <IonLabel className="ion-text-wrap" >
            <h2>Weinjahrgänge</h2>
            <p>Vino Mobile App</p>
          </IonLabel>
        </IonItem>
      </>
    )
  }
  
  render() {
    return (
      <Page title="Übersichtstabellen" large>
        <IonList>

          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte">
            <IonLabel className="ion-text-wrap" >
              <h2>Schadstoffgrenzwerte</h2>
              <p>Tabellen mit Grenzwerten von Schadstoffen</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/bauschadstoffe">
            <IonLabel className="ion-text-wrap" >
              <h2>Bauschadstoffe (Asbest, PCB etc.)</h2>
              <p>Polludoc, Checklisten, Entsorgungstabellen, Dringlichkeit nach FACH, Anzahl Raumluftmessungen, Übersicht Asbestmineralien</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/schadstoffgrenzwerte/neophyten">
            <IonLabel className="ion-text-wrap" >
              <h2>Neophyten</h2>
              <p>Invasive Arten</p>
            </IonLabel>
          </IonItem>

{/* 
          <IonItem routerLink="/uebersichtstabellen/einbautiefen">
            <IonLabel className="ion-text-wrap" >
              <h2>Bauen im Grundwasser</h2>
              <p>Vorschriften pro Kanton</p>
            </IonLabel>
          </IonItem> */}


          <IonItem routerLink="/uebersichtstabellen/lvacodes">
            <IonLabel className="ion-text-wrap" >
              <h2>LVA-Codes</h2>
              <p>Zuordnung zu den Abfallkategorien</p>
            </IonLabel>
          </IonItem>

          <IonItem routerLink="/uebersichtstabellen/dichten" lines="none">
            <IonLabel className="ion-text-wrap" >
              <h2>Dichten (t/m<sup>3</sup>)</h2>
              <p>Umrechnungsfaktoren m<sup>3</sup> → t für mineralische Bauabfälle</p>
            </IonLabel>
          </IonItem>

          {localStorage.getItem('unlocked') == 'true' && this.renderInternalStuff()}

        </IonList>
      </Page>
    )
  }

}
