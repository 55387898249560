
let neophyten = [
    {
      "Neophyt": "Ambrosie",
      "Wissenschaftlicher Name": "Ambrosia artemisiifolia",
      "Status1,3,4,5": "Bekämpfungs-/Meldepflicht Kt. ZH, verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "nv",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 2,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1, Deklarationspflicht ",
      "Bemerkungen": "Meldepflicht / Beizug Kanton, Arbeiten mit Schutzkleidung, Entsorgung Grüngut in KVA, Vermehrung durch Versamung",
      amountImages: 3
    },
    {
      "Neophyt": "Asiatische Staudenknöteriche",
      "Wissenschaftlicher Name": "Reynoutria ssp. (Fallopia ssp., Polygonium cuspidatum, Polygonum polystachyum)",
      "Status1,3,4,5": "Bekämpfungspflicht, PK-Verfahren Kt. ZH, verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>II</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": '5*',
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": '3*',
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6, Deklarationspflicht ",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Deklarationspflicht am Entsorgungsort, Vermehrung vegetativ, enorme Verschleppungsgefahr, kleinste Pflanzenteile treiben wieder aus",
      amountImages: 4
    },
    {
      "Neophyt": "Essigbaum",
      "Wissenschaftlicher Name": "Rhus typhina",
      "Status1,3,4,5": "Bekämpfungspflicht, PK-Verfahren Kt. ZH, verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>II</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": '10*',
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": '1*',
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6, Deklarationspflicht ",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Deklarationspflicht am Entsorgungsort, Vermehrung vegetativ, Versamung möglich, kein Rückschnitt, da sonst massive Stockausschläge und Wurzelbrut",
      amountImages: 11
    },
    {
      "Neophyt": "Erdmandelgras",
      "Wissenschaftlicher Name": "Cyperus esculentus ",
      "Status1,3,4,5": "Meldepflicht Kt. ZH, potenziell invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>II</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 1,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">2, Deklarationspflicht ",
      "Bemerkungen": "Meldepflicht / Beizug Kanton, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Vermehrung vegetativ, Verschleppung der Mandeln durch gründliche Gerätereinigung verhindern",
      amountImages: 2
    },
    {
      "Neophyt": "Riesen-Bärenklau",
      "Wissenschaftlicher Name": "Heracleum mantegazzianum",
      "Status1,3,4,5": "Bekämpfungs-/Meldepflicht Kt. ZH, verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 7,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1, Deklarationspflicht ",
      "Bemerkungen": "Arbeiten mit Schutzkleidung (Phototoxizität), Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Aushubradius je nach Bestand bzw. projektspezifisch anzupassen, Vermehrung durch Versamung, selten vegetativ, Pfahlwurzel mit hoher Regenerationsfähigkeit",
      amountImages: 3
    },
    {
      "Neophyt": "Schmalblättriges Greiskraut",
      "Wissenschaftlicher Name": "Senecio inaequidens",
      "Status1,3,4,5": "Bekämpfungs-/Meldepflicht Kt. ZH, verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 10,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1, Deklarationspflicht ",
      "Bemerkungen": "Für Weidetiere potenziell giftig, starke Versamung, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Aushubradius je nach Bestand bzw. projektspezifisch anzupassen, Vermehrung durch Versamung, selten vegetativ",
      amountImages: 3
    },
    {
      "Neophyt": "Drüsiges Springkraut",
      "Wissenschaftlicher Name": "Impatiens glandulifera",
      "Status1,3,4,5": "verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub></span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 6,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Wurzeln nur oberflächlich, Samen ca. 6 m Umkreis, oft entlang (Bach)ufern, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung durch Ausreissen vor Samenreife (Juli), Vermehrung durch Versamung, selten vegetativ",
      amountImages: 3
    },
    {
      "Neophyt": "Einjähriges Berufkraut",
      "Wissenschaftlicher Name": "Erigeron annuus ",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub></span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 2,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, bildet sehr schnell dichte Bestände, Bekämpfung durch mind. 3x jährliches Ausreissen samt Wurzeln (früh beginnen!, Mai), Vermehrung durch (starke) Versamung, selten vegetativ",
      amountImages: 4
    },
    {
      "Neophyt": "Götterbaum",
      "Wissenschaftlicher Name": "Ailanthus altissima",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 15,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 1,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6",
      "Bemerkungen": "Arbeiten mit Schutzkleidung, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Aushubradius je nach (Baum)bestand bzw. projektspezifisch anzupassen: Baumkronendurchmesser + 2 m, oft ausgedehntes Wurzelsystem (kein Fällen/Rückschnitt, sonst massive Wurzelbrut, Bekämpfung durch Ringeln + regelmässiges Ausreissen Stockausschläge/Pflanzentriebe), Vermehrung vegetativ + Versamung",
      amountImages: 5
    },
    {
      "Neophyt": "Nordamerikanische Goldruten",
      "Wissenschaftlicher Name": "Solidago spp. (S. canadensis, S. gigantea, S. nemoralis)",
      "Status1,3,4,5": "verbotene invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub></span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 3,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, je nach Bestand Aushubradius anpassen, Bekämpfung: mind. 2x jährliches Ausreissen samt Ausläufern, Vermehrung durch (starke) Versamung und vegetativ",
      amountImages: 5
    },
    {
      "Neophyt": "Robinie",
      "Wissenschaftlicher Name": "Robinia pseudoacacia",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub> (Empfehlung FRIEDLIPARTNER: nv)</span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 15,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 1,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich dringend empfohlen, Aushubradius je nach (Baum)bestand bzw. projektspezifisch anzupassen: Baumkronendurchmesser + 2 m, oft ausgedehntes Wurzelsystem (kein Fällen/Rückschnitt, sonst massive Wurzelbrut, Bekämpfung durch Ringeln + regelmässiges Ausreissen Stockausschläge/Pflanzentriebe), Vermehrung vegetativ + Versamung",
      amountImages: 3
    },
    {
      "Neophyt": "Sommerflieder",
      "Wissenschaftlicher Name": "Buddleja davidii",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": <span>ev<sub>I</sub></span>,
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 10,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Aushubradius je nach Bestand bzw. projektspezifisch anzupassen, Bekämpfung: Ausstocken der Wurzeln (ganze Stöcke), Achtung: oberirdische Pflanzenteile (Äste) können neue Pflanzen bilden (Transport in geschlossenen Säcken), Nachkontrolle Wurzelausschläge + Keimlinge, Vermehrung durch (starke) Versamung und vegetativ",
      amountImages: 5
    },
    {
      "Neophyt": "Armenische Brombeere",
      "Wissenschaftlicher Name": "Rubus armeniacus",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.5,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Dichtes Wurzelwerk ist stark ausschlagfähig, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: mehrjähriges Mähen + Ausstocken Mutterknollen, Vermehrung vegetativ + Versamung",
      amountImages: 3
    },
    {
      "Neophyt": "Blauglockenbaum",
      "Wissenschaftlicher Name": "Paulownia tomentosa",
      "Status1,3,4,5": "potenziell invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 10,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 2,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6",
      "Bemerkungen": "Junge Bäume sind strauchartig mit riesigen seidigen Blättern, Blüte erst ab ca. 8 Jahren, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Aushubradius je nach (Baum)bestand bzw. projektspezifisch anzupassen: Baumkronendurchmesser + 2 m, (Bekämpfung durch Ringeln + regelmässiges Ausreissen Stockausschläge/Pflanzentriebe), Vermehrung vegetativ + Versamung",
      amountImages: 4
    },
    {
      "Neophyt": "Chinesische Hanfpalme",
      "Wissenschaftlicher Name": "Trachycarpus fortunei",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.5,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: junge Pflanzen ausstocken, grössere Pflanzen fällen, Vermehrung durch Versamung",
      amountImages: 2
    },
    {
      "Neophyt": "Jungfernrebe",
      "Wissenschaftlicher Name": "Parthenocissus inserta, P. quinquefolia var. vitacea",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.5,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Schnellwüchsige Kletterpflanze, bei Bauvorhaben Entsorgung Grüngut in KVA, Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: Mehrjähriges Auszerren + Ausgraben (2x pro Jahr), hohe Verschleppungsgefahr (kleine Sprosse treiben wieder aus), Vermehrung vegetativ + Versamung",
      amountImages: 2
    },
    {
      "Neophyt": "Henrys Geissblatt",
      "Wissenschaftlicher Name": "Lonicera henryi",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.5,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.3,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Immergrüne Schlingpflanze, bildet verholzte, teils lianenartige Stängel, Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: Mehrjähriges Auszerren + Ausgraben (2x pro Jahr), hohe Verschleppungsgefahr (kleine Sprosse treiben wieder aus), Vermehrung vegetativ + Versamung",
      amountImages: 1
    },
    {
      "Neophyt": "Kirschlorbeer",
      "Wissenschaftlicher Name": "Prunus laurocerasus",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 2,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 1.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Aushubradius und Aushubtiefe je nach Bestand bzw. projektspezifisch anzupassen, Bekämpfung: Ausstocken der Wurzeln (ganze Stöcke), Nachkontrolle Wurzelausschläge + Keimlinge, Vermehrung durch Versamung + vegetativ",
      amountImages: 4
    },
    {
      "Neophyt": "Seidiger Hornstrauch",
      "Wissenschaftlicher Name": "Cornus sericea",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 5,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 1,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">6",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Aushubradius je nach Bestand bzw. projektspezifisch anzupassen, Bekämpfung: Ausstocken der Wurzeln (ausschlagfähiges, weit verzweigtes Wurzelsystem; Ausreissen gegen Wuchsrichtung), Nachkontrolle Wurzelausschläge + Ableger, Vermehrung durch Versamung + vegetativ",
      amountImages: 1
    },
    {
      "Neophyt": "Syrische Seidenpflanze",
      "Wissenschaftlicher Name": "Asclepias syriaca",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.2,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.4,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: systematisches Entfernen der Wurzeln, Vermehrung vegetativ + Versamung",
      amountImages: 2
    },
    {
      "Neophyt": "Topinambur",
      "Wissenschaftlicher Name": "Helianthus tuberosus",
      "Status1,3,4,5": "invasive gebietsfremde Pflanze\n",
      "VHVB-Verwertungsklasse": "vp",
      "Verbreitungsradius (grosse Exemplare)2,6,*  [m]": 0.2,
      "Durchwurzelungstiefe (grosse Exemplare)2,6,* [m]": 0.5,
      "Mind. Überdeckung Kiesgrube/Deponie [m]": ">1",
      "Bemerkungen": "Entsorgung Grüngut in KVA, bei Bauvorhaben Aushub und Entsorgung biologisch belasteter Boden im Wurzelbereich empfohlen, Bekämpfung: Ausreissen, mehrjährige Nachkontrolle, Vermehrung durch vegetativ durch Rhizomausschlag, Samen reifen meist nicht aus",
      amountImages: 2
    }
  ]

export default neophyten

